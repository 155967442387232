import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FullScreenPlayer from '../../components/FullScreenPlayer';
import FooterStyle from '../../components/partials/backend/footerstyle/footerstyle';
import HeaderStyle1 from '../../components/partials/backend/headerstyle/headerstyle1';
import ShowDetailModal from '../../components/ShowDetailModal';
import Layout1Route from '../../router/main-layout-route';
import {
  ChangeYaxis
} from '../../store/Actions/sharedActions';

const MainLayout = (props) => {

  // const {
  //   ChangeYaxis,
  // } = props

  
  useEffect(() => {
    let backToTop
    backToTop = document.getElementById("back-to-top")

    if (backToTop !== null && backToTop !== undefined) {
      document.getElementById("back-to-top").classList.add('animated', 'fadeOut')
      window.addEventListener('scroll', (e) => {
        try {
          if (document.documentElement.scrollTop > 300) {
            document.getElementById("back-to-top").classList.remove("fadeOut")
            document.getElementById("back-to-top").classList.add("fadeIn")
          } else {
            document.getElementById("back-to-top").classList.remove("fadeIn")
            document.getElementById("back-to-top").classList.add("fadeOut")
          }
        } catch (e) {
          console.error(e)
        }
      })
      
      document.querySelector('#top').addEventListener('click', (e) => {
        e.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
    }
  }, [])

  return (
    <>
      <div id="back-to-top">
        <Link className="top" to="#" id="top"> <i className="fa fa-angle-up"></i></Link>
      </div>
      <div className="wraper">
        <HeaderStyle1 />
        <div className="content-page" id="content-page">
          <Layout1Route/>
        </div>
      </div>
      <FooterStyle />
      <ShowDetailModal />
      <FullScreenPlayer />
    </>
  )
}


const mapStateToProps = ({ movie, shared }) => ({
  movie, shared
})

export default connect(mapStateToProps, {
  ChangeYaxis,
})(MainLayout)