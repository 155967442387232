import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { CopyLink } from "../helperComponents/CopyLink";
import { baseSharingURL } from '../../../../config/baseURL';

const ShareIcon = ({name, id}) => {

  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const constructedUrl = `${baseSharingURL}${name.split(" ").join("-").toLowerCase()}-${id}`;
    console.log('Constructed URL:', constructedUrl); // Check the constructed URL
    setShareUrl(constructedUrl);
  }, [name, id]);



    return ( 
        <li className="share">
          <span>
            <i className="custom-icon ri-share-fill"></i>
          </span>
          <div className="share-box">
            <div className="d-flex align-items-center">
              <FacebookShareButton
                url={shareUrl}
                hashtag="#multitude"
                title={name}
              >
                <i className="ri-facebook-fill"></i>
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                hashtags={["multitude"]}
                title={name}
                className="share-ico"
              >
                <i className="ri-twitter-fill"></i>
              </TwitterShareButton>
              <Link to="#" 
                onClick={() => {CopyLink(name, id); setCopied(!copied)}} 
                className="share-ico">
                <i className={copied ? "ri-links-fill-copied ri-links-fill" : "ri-links-fill"}></i>
              </Link>
            </div>
          </div>
        </li>
     );
}
 
export default ShareIcon;