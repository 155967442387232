import { toast } from 'react-toastify'
import store from '../'
import { baseURL } from '../../config/baseURL'
import { formatDate, formatTime } from '../../utils/DateUtils'
import {
    CHANGE_CONTINUE_WATCHING,
    CHANGE_CURRENT_SEASON_EPISODES,
    CHANGE_DOWNLOADED_SEASONS,
    CHANGE_EPISODES_PER_SEASON,
    CHANGE_FOLLOWED_SHOWS_IDS,
    CHANGE_FULL_SCREEN_EPISODE,
    CHANGE_GENRE,
    CHANGE_GENRE_PICKER_SWAP_PANNEL,
    CHANGE_GENRE_SECTIONS,
    CHANGE_LIKED,
    CHANGE_LOADER,
    CHANGE_MOST_TREDING_SHOWS,
    CHANGE_NEW_EPISODES,
    CHANGE_PAUSED_TV_SHOWS,
    CHANGE_POPULAR_MOVIES,
    CHANGE_POPULAR_SEARCHES,
    CHANGE_POSTERS,
    CHANGE_PUBLISHER_DETAIL,
    CHANGE_PUBLISHER_SHOWS,
    CHANGE_RECOMMENDED_SHOWS,
    CHANGE_SEARCHED_SHOWS,
    CHANGE_SEARCH_QUERY,
    CHANGE_SEASON_DETAIL,
    CHANGE_SELECTED_CATEGORY,
    CHANGE_SELECTED_EPISODE_AND_CURRENT_TIME_FOR_FULL_SCREEN,
    CHANGE_SELECTED_EPISODE_CURRENT_TIME_FOR_FULL_SCREEN,
    CHANGE_SELECTED_EPISODE_FOR_FULL_SCREEN,
    CHANGE_SELECTED_PUBLISHER_ID,
    CHANGE_SHOWS_BY_CATEGORY,
    CHANGE_SHOW_DETAIL_EPISODES_LOADER,
    CHANGE_SHOW_FULL_SCREEN_PLAYER,
    CHANGE_SHOW_MOVIE_INFO_MODAL,
    CHANGE_SIMILAR_SHOWS,
    CHANGE_STATUS,
    CHANGE_TRENDING_SHOWS,
    CURRENT_CATEGORY,
    CURRENT_VIDEO,
    DELETE_ALL_DOWNLOADS,
    DELETE_EPISODES_FROM_EPISODES_PER_SEASON,
    FOLLOW_SHOW,
    REMOVE_LOADER_AND_RESET_STATUS,
    SEARCHED_QUERY,
    TOGGLE_IS_FOLLOWING_IN_SHOW_DETAILS,
    UNFOLLOW_SHOW,
    CHANGE_NOTIFICATIONS,
    CHANGE_POPULAR_HORROR_SHOWS,
    CHANGE_UP_COMING_SHOWS,
    CHANGE_LANGUAGE_DATA,
    CHANGE_PROGRESS_EVENT,
    CHANGE_SHOW_TO_EDIT,
    CHANGE_SEASON_TO_EDIT,
    CHANGE_EPISODE_TO_EDIT,
    // CHANGE_EDIT_FORM_DATA,
    REFRESH_SIDEBAR_COMPONENT,
    CHANGE_UPLOAD_FINAL,

} from './types'

//STATE MANAGMENT
export const ChangeRefreshSideBar = (val) => {
    return async (dispatch) => {
        dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: val})
    }
}

export const ChangeSelectedEpisodeCurrentTimeForFullScreen = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SELECTED_EPISODE_CURRENT_TIME_FOR_FULL_SCREEN, payload: val })
    }
}
export const ChangeSelectedEpisodeForFullScreen = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SELECTED_EPISODE_FOR_FULL_SCREEN, payload: val })
    }
}
export const ChangeSelectedPublisherId = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SELECTED_PUBLISHER_ID, payload: val })
    }
}
export const ChangeSelectedCategory = (id, name) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SELECTED_CATEGORY, payload: { id, name } })
    }
}
export const ChangeCurrentCategory = (val) => {
    return async (dispatch) => {
        dispatch({ type: CURRENT_CATEGORY, payload: val })
    }

}
export const ChangeCurrentVideo = (val) => {
    return async (dispatch) => {
        dispatch({ type: CURRENT_VIDEO, payload: val })
    }

}
export const ChangeShowMovieInfoModal = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SHOW_MOVIE_INFO_MODAL, payload: val })
    }
}
export const ChangeShowGenrePickerPannel = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GENRE_PICKER_SWAP_PANNEL, payload: val })
    }
}
export const ChangeSearchedValue = (val) => {
    return async (dispatch) => {
        dispatch({ type: SEARCHED_QUERY, payload: val })
    }

}

//FETCH AND DISPATCH
export const ChangeSearchQuery = (history, val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        dispatch({ type: CHANGE_SEARCH_QUERY, payload: val })

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${store.getState().shared.userData.token}`);

        const formdata = new FormData();
        formdata.append("keyword", val);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(baseURL + "/api/search", requestOptions)
            .then((response) => {
                return response.json()
            }).then((json) => {
                if (json.status_code === 200) {
                    dispatch({ type: CHANGE_SEARCHED_SHOWS, payload: json.data })
                    history.push("/shows-by-search-query")
                } else {
                    console.error("Something went wrong while fetching the popular searches")
                }
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            }).catch((error) => {
                console.error(error)
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            })
    }
}
export const Search = () => {
    return async (dispatch) => {
    }
}
export const PopularSearches = () => {
    return async (dispatch) => {
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const postRequestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        }
        dispatch({ type: CHANGE_LOADER, payload: true })
        fetch(baseURL + '/api/popular-searches',
            postRequestOptions
        ).then((response) => {
            return response.json()
        }).then((json) => {
            if (json.status_code === 200) {
                let data = json.data.reverse()
                dispatch({ type: CHANGE_POPULAR_SEARCHES, payload: data })
            } else {
                console.error("Something went wrong while fetching the popular searches")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}
export const GetTrendingScreenData = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_LOADER, payload: true })

            const myHeaders = new Headers()
            myHeaders.append("ac", "application/json")
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token
            )
            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            }
            const postRequestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            }

            let [trendingShows, recommendedShows, mostTrendingShows] = await Promise.all([
                fetch(baseURL + '/api/trending-shows', requestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
                fetch(baseURL + '/api/suggested-shows/1', requestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
                fetch(baseURL + '/api/most-trending-shows', postRequestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
            ])

            if (
                trendingShows.status === 200 &&
                recommendedShows.status === 200 &&
                mostTrendingShows.status === 200
            ) {
                dispatch({ type: CHANGE_TRENDING_SHOWS, payload: trendingShows.data.data })
                dispatch({ type: CHANGE_RECOMMENDED_SHOWS, payload: recommendedShows.data.data })
                dispatch({ type: CHANGE_MOST_TREDING_SHOWS, payload: mostTrendingShows.data.data })
            } else {
                console.error("Error while fetching data")
            }
            dispatch({ type: CHANGE_LOADER, payload: false })
        } catch (error) {
            console.error("ERROR")
            console.error(error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        }
    }
}
export const GetAllShowsByPublisherId = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        let type
        if (store.getState().movie.selectedPublisherId == null) {
            type = 1
        } else {
            type = 0
        }
        const formdata = new FormData()
        formdata.append("type", type)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        }
        let url = "/multitude/original"
        fetch(baseURL + "/api" + url,
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status === 200) {
                dispatch({ type: CHANGE_PUBLISHER_SHOWS, payload: json.data })
            } else {
                console.error("Something went wrong while fetching publisher's season")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}
export const GetAllEpisodesOfSpecificSeason = (id, stopLoader) => {
    return async (dispatch) => {
        if(!stopLoader)
            dispatch({ type: CHANGE_SHOW_DETAIL_EPISODES_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }
        fetch(baseURL + '/api/publisher/episodes/' + id,
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (json.status_code === 200) {
                //let episodesToFilterOut = [63, 68, 70]
                //let filteredEpisodes = json.data.filter(obj => !episodesToFilterOut.includes(obj.id)) //! remove this filter after episodes link issue is resolved
                //dispatch({ type: CHANGE_CURRENT_SEASON_EPISODES, payload: filteredEpisodes })
                dispatch({ type: CHANGE_CURRENT_SEASON_EPISODES, payload: json.data })
            } else {
                console.error("Something went wrong while fetching episodes of season " + id)
            }
            dispatch({ type: CHANGE_SHOW_DETAIL_EPISODES_LOADER, payload: false })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: CHANGE_SHOW_DETAIL_EPISODES_LOADER, payload: false })
        })
    }
}

export const GetEpisodeVideos = (id, showFullscreenPlayer, showLoader) => {
    return async (dispatch) => {
        if (showLoader)
            dispatch({ type: CHANGE_LOADER, payload: true })
        dispatch({ type: CHANGE_FULL_SCREEN_EPISODE, payload: null })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + '/api/episode/video/' + id,
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (json.status_code === 200) {
                const { process_status, videos } = json.data;
                if (process_status === 0 || (videos?.length === 0)) {
                    toast("Currently processing videos for this episode, please try again later.");
                    dispatch({ type: CHANGE_SHOW_FULL_SCREEN_PLAYER, payload: false });
                    dispatch({ type: CHANGE_FULL_SCREEN_EPISODE, payload: null });
                } else {
                    dispatch({ type: CHANGE_FULL_SCREEN_EPISODE, payload: json.data });
                    if (showFullscreenPlayer) {
                        dispatch({ type: CHANGE_SHOW_FULL_SCREEN_PLAYER, payload: true });
                    }
                }
            } else {
                console.error("Something went wrong while fetching episode videos " + id);
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null });
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}

//pops open the modal if you do not pass in a [dontOpenModal] = true same with [dontLoad]
//gets details from the show. seems to fetch episode/season/show details all together
export const GetShowDetails = (id, dontLoad, dontOpenModal, stopAllEpData) => {
    return async (dispatch) => {
        if (!dontLoad)
            dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + '/api/show/details/' + id,
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_SEASON_DETAIL, payload: json.data })
                if (!dontOpenModal)
                    dispatch({ type: CHANGE_SHOW_MOVIE_INFO_MODAL, payload: true })
                if(!stopAllEpData)
                    dispatch(GetAllEpisodesOfSpecificSeason(json.data.seasons[0].season_id))
            } else {
                console.error("Something went wrong while fetching show details")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}
export const GetShowsByCategory = (history, id) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        // myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        fetch(baseURL + '/api/showsbycategory?category_id=' + id,
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status === 200) {
                dispatch({ type: CHANGE_SHOWS_BY_CATEGORY, payload: json.data })
                history.push("/shows-by-category")
            } else {
                console.error("Something went wrong while fetching shows by category")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}

export const GetSimilarShowsByCategory = (categoryId, limit = 15, load) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        // myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + '/api/showsbycategory?category_id=' + categoryId + '?limit=' + limit,
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status === 200) {
                dispatch({ type: CHANGE_SIMILAR_SHOWS, payload: json.data })
            } else {
                console.error("Something went wrong while fetching similar shows by category")
            }
            // if(!load){
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            // }
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}

export const GetAllCategories = () => {
    return async (dispatch) => {
        fetch(baseURL + '/api/categories'
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status === 200) {
                dispatch({ type: CHANGE_GENRE, payload: json.data })
            } else {
                console.error("Something went wrong while fetching the categories")
            }
        }).catch((error) => {
            console.error(error)
        })
    }
}

export const GetPosters = () => {
    return async (dispatch) => {
        
        dispatch({ type: CHANGE_LOADER, payload: true })
            const myHeaders = new Headers()
            myHeaders.append("ac", "application/json")
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
            const postRequestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            }
            await Promise.allSettled([
                fetch(baseURL + "/api/dynamic-shows", postRequestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
               ])
                .then(resArray => {
                resArray[0].status === 'fulfilled' && resArray[0].value.data.status_code === 200 && dispatch({ type: CHANGE_POSTERS, payload: resArray[0].value.data.data })
                })
                .catch(error => {
                    console.error('error', error)
                });
            dispatch({ type: CHANGE_LOADER, payload: false })
    }
}
export const GetContinueWatchingShows = () => {
    return async (dispatch) => {
            const myHeaders = new Headers()
            myHeaders.append("ac", "application/json")
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
            const postRequestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            }
            await Promise.allSettled([
                fetch(baseURL + '/api/continue-watching', postRequestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
            ])
            .then(resArray => {
                resArray[0].status === 'fulfilled' && resArray[0].value.data.status_code === 200 && dispatch({ type: CHANGE_CONTINUE_WATCHING, payload: resArray[0].value.data.data })
            })
            .catch(error => {
                console.error('error', error)
            });
    }
}
export const GetAllData = () => {
    return async (dispatch) => {
            const myHeaders = new Headers()
            myHeaders.append("ac", "application/json")
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            }
            const postRequestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            }
            await Promise.allSettled([
                fetch(baseURL + "/api/popularshows?limit=10", requestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
                fetch(baseURL + '/api/allShows', requestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
                fetch(baseURL + '/api/suggested-shows/60', requestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
                fetch(baseURL + '/api/categories', requestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
                fetch(baseURL + '/api/followed-shows', postRequestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
                fetch(baseURL + '/api/notifications', requestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
                fetch(baseURL + '/api/popularHorrorshows', requestOptions).then(response => response.json().then(data => ({ status: response.status, data, }))),
            ]).then(resArray => {
                resArray[0].status === 'fulfilled' && resArray[0].value.data.status_code === 200 && dispatch({ type: CHANGE_POPULAR_MOVIES, payload: resArray[0].value.data.data })
                resArray[1].status === 'fulfilled' && resArray[1].value.data.status_code === 200 && dispatch({ type: CHANGE_GENRE_SECTIONS, payload: Object.keys(resArray[1].value.data.data).map((key) => {
                    const value = resArray[1].value.data.data[key]
                    return ({ "category": key.split('_').join(' and '), "shows": value })
                }
                )})
                resArray[2].status === 'fulfilled' && resArray[2].value.data.status_code === 200 && dispatch({ type: CHANGE_RECOMMENDED_SHOWS, payload: resArray[2].value.data.data })
                resArray[3].status === 'fulfilled' && resArray[3].value.data.status_code === 200 && dispatch({ type: CHANGE_GENRE, payload: resArray[3].value.data.data })
                resArray[4].status === 'fulfilled' && resArray[4].value.data.status_code === 200 && dispatch({ type: CHANGE_FOLLOWED_SHOWS_IDS, payload: resArray[4].value?.data?.data?.show_ids })
                resArray[5].status === 'fulfilled' && resArray[5].value.data.status_code === 200 && dispatch({ type: CHANGE_NOTIFICATIONS, payload: resArray[5].value?.data?.data })
                resArray[6].status === 'fulfilled' && resArray[6].value.data.status_code === 200 && dispatch({ type: CHANGE_POPULAR_HORROR_SHOWS, payload: resArray[6].value?.data?.data })
            }).catch(error => {
                console.error('error', error)
            });
    }
}

export const GetFollowingShows = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + '/api/following/shows',
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status === 200) {
                dispatch({ type: CHANGE_LIKED, payload: json.data })
            } else {
                console.error("Something went wrong while fetching the following shows")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}
export const FollowedShows = () => {
    return async (dispatch) => {
        console.log("FOLLOWEDSHOWS CALLED")
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(baseURL + "/api/followed-shows",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_FOLLOWED_SHOWS_IDS, payload: json.data.show_ids })
            }
            else {
                console.error("Error while fetching like &  show")
            }
        }).catch(error => {
            console.error('error', error)
        });
    }
}

export const FollowShow = (showId, isFollowing) => {
    return async (dispatch) => {

        dispatch({ type: TOGGLE_IS_FOLLOWING_IN_SHOW_DETAILS, payload: showId })
        const myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        if (isFollowing) {
            dispatch({ type: FOLLOW_SHOW, payload: showId })
        } else {
            dispatch({ type: UNFOLLOW_SHOW, payload: showId })
        }
        const formdata = new FormData()
        formdata.append("show_id", showId)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/follow-show",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(result => {
            if (result.status_code === 200) {
                if (result.data.id !== undefined) {
                    toast("Added to following shows!")
                } else {
                    toast("Removed from following shows!")
                }
            }
            else {
                console.error("Error while following show")
                dispatch({ type: TOGGLE_IS_FOLLOWING_IN_SHOW_DETAILS, payload: null })
            }
        }).catch(error => {
            console.log('error', error)
            dispatch({ type: TOGGLE_IS_FOLLOWING_IN_SHOW_DETAILS, payload: null })
        })
    }
}

export const GetNewTvShows = (load) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        // myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IitLMTdDNHRFUVdPdTRaSGhRaUVpdmc9PSIsInZhbHVlIjoiem15N3RKMFYzcEhQSE91RlhiUmVvQ25PRUtESjNnd2JIOHQ3TlBkQmx1dlNHSnJVMXpLZnFrQ1Q0NVlKUFZZWVJHZ2p6cElhOVV5SE9PazdRbExqQkxPV2UyMWpMbndnak5Wbm1xMGM4dXlYdGowTjJNNkh0aTBCN3V5TGxNYSsiLCJtYWMiOiI4MjM1YTQ5M2UzODUyZTZlYzhmZTJlNGNhYTA3MjFmYjI1MTQ3Y2M4ZjVjZTZmM2U4NTZhZTFhODA3YjM5M2M3IiwidGFnIjoiIn0%3D laravel_session=eyJpdiI6IjNhSnlmRThvWUlrZ3lCVjRMWEJscUE9PSIsInZhbHVlIjoiMzZKNlZab3ZqWUJ1Zk1UWFVvcmprRnVZRExuT1l4RnhUejNZT0h3TW5RK05SVExvdm9CdU03cGdldmFYUVpldFI1QUxxTmQxdE5IVHRxQThTS2owWDd1c3ArNGVyeWF5akFYcGh4RlZsMzhpTVg1cmJYZDlHaGs3alRpL2E3MmEiLCJtYWMiOiIzN2JmMDI5YWVmY2NjMWQ5MjE2MzFkNWJjYzlkYjhiNzUyOWE3YTMzYTQzZGllZGJlNDZmNDA4ODhmZDY3MGYyIiwidGFnIjoiIn0%3D")
        // const formdata = new FormData()
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            // body: formdata,
            redirect: 'follow'
        }
        fetch(baseURL + '/api/newEpisodes?limit=10',
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if(!load){
                dispatch({ type: CHANGE_LOADER, payload: false })
            }
            if (store.getState().shared.status === 200) {
                dispatch({ type: CHANGE_NEW_EPISODES, payload: json.data })
            } else {
                console.error("Something went wrong while fetching the new shows")
            }
            // dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            dispatch({ type: CHANGE_LOADER, payload: false })
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}
export const PauseEpisode = (episode_id, time, watch_time) => {
    return async (dispatch) => {
        const myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const formdata = new FormData()
        formdata.append("episode_id", episode_id)
        formdata.append("time", time)
        formdata.append("watch_time", watch_time)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            keepalive: true,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/pause-episode",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(result => {
            if (result.status_code === 200) {
                let pausedTvShowsTemp = store.getState().movie.pausedTvShows
                let seasonIndexToUpdate = pausedTvShowsTemp.findIndex(obj => (obj.seasonId === result.data.season_id && obj.episodeId === result.data.episode_id))
                if (seasonIndexToUpdate >= 0) {
                    pausedTvShowsTemp[seasonIndexToUpdate].episodeId = result.data.episode_id
                    pausedTvShowsTemp[seasonIndexToUpdate].pauseTime = result.data.pause_time
                } else {
                    pausedTvShowsTemp.push({
                        seasonId: result.data.season_id,
                        episodeId: result.data.episode_id,
                        pauseTime: result.data.pause_time,
                        // landscapeImage: result.data.landscape_image,
                    })
                }
                dispatch({ type: CHANGE_PAUSED_TV_SHOWS, payload: pausedTvShowsTemp })
                console.info("Episode id & time are successfully stored")
            }
            else {
                console.error("Error while storing the episode & time")
            }
        }).catch(error => {
            console.log('error', error)
        })
    }
}
export const PlayNow = (showId, dontLoad) => {
    return async (dispatch) => {
        try {
            if (!dontLoad)
                dispatch({ type: CHANGE_LOADER, payload: true })
            const myHeaders = new Headers()
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
            const formdata = new FormData()
            formdata.append("show_id", showId)
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            }
            let result = await fetch(baseURL + "/api/play-now",
                requestOptions
            ).then(response => response.json().then(data => ({ status: response.status, data, })))

            const { data, status_code } = result.data
            if (status_code === 200) {
                if (data?.pause_time !== undefined) {
                    dispatch({
                        type: CHANGE_SELECTED_EPISODE_AND_CURRENT_TIME_FOR_FULL_SCREEN,
                        payload: { pause_time: data?.pause_time, episode_id: data?.episode_id }
                    })
                    dispatch(GetEpisodeVideos(data?.episode_id, true))
                    dispatch(GetShowDetails(showId, true, true))
                    // toast("Playing from where you left")
                } else {
                    console.info("NO PAUSE_TIME FOUND")
                    if (data?.episode.length !== 0 && data?.episode !== undefined) {
                        dispatch({
                            type: CHANGE_SELECTED_EPISODE_AND_CURRENT_TIME_FOR_FULL_SCREEN,
                            payload: { pause_time: "0.00", episode_id: data?.episode[0].id }
                        })
                        dispatch(GetEpisodeVideos(data?.episode[0].id, true))
                        dispatch(GetShowDetails(showId, true, true))
                        // toast("Playing show from the start")
                    } else {
                        toast("No episodes found in this show!")
                        dispatch({ type: CHANGE_LOADER, payload: false })
                    }
                }
            }
            else {
                console.error("Error while playing now")
            }

            // dispatch({ type: CHANGE_LOADER, payload: false })

        } catch (error) {
            dispatch({ type: CHANGE_LOADER, payload: false })
            console.error(error)
        }


    }
}
export const UserDetails = () => {
    return async (dispatch) => {
        if (store.getState().movie.selectedPublisherId != null) {
            dispatch({ type: CHANGE_LOADER, payload: true })
            const myHeaders = new Headers()
            myHeaders.append("ac", "application/json")
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
            const formdata = new FormData()
            formdata.append("user_id", store.getState().movie.selectedPublisherId)
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow',
            }
            const url = "/user-details"
            fetch(baseURL + "/api" + url,
                requestOptions
            ).then((response) => {
                dispatch({ type: CHANGE_STATUS, payload: response.status })
                return response.json()
            }).then((json) => {
                if (store.getState().shared.status === 200) {
                    dispatch({ type: CHANGE_PUBLISHER_DETAIL, payload: json.data })
                } else {
                    console.error("Something went wrong while fetching publisher's detail")
                }
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            }).catch((error) => {
                console.error(error)
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            })
        } else {
            const data = {
                profile_photo_path: '',
                name: 'Multitude Original',
                about: 'We are a group of independent creators. Our content is made by makers',
                address: 'Office 45, Street 6, Mountain Heights, MN, USA',
                created_at: '2020-07-13T01:06:17.000000Z',
                email: 'admin@multitude.com',
                phone: '+1-234-567-1234',
            }
            dispatch({ type: CHANGE_PUBLISHER_DETAIL, payload: data })
        }
    }
}
export const DownloadedShows = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/downloaded-shows",
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status === 200) {
                dispatch({ type: CHANGE_DOWNLOADED_SEASONS, payload: json.data })
            } else {
                console.error("Something went wrong while fetching the downloaded seasons")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}
export const DownloadedEpisodesSeason = (showId) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const formdata = new FormData()
        formdata.append("show_id", showId)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/downloaded-episodes-season",
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status === 200) {
                dispatch({ type: CHANGE_EPISODES_PER_SEASON, payload: json.data })
            } else {
                console.error("Something went wrong while fetching the downloaded episodes per season")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}
export const DeleteDownloadedEpisodes = () => {
    return async (dispatch) => {
        console.log("DELETEDOWNLOADEDEPISODES")
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/delete-downloaded-episodes",
            requestOptions
        ).then((response) => {
            return response.json()
        }).then((json) => {
            if (json.status_code === 200) {
                dispatch({ type: DELETE_ALL_DOWNLOADS, payload: null })
            } else {
                console.error("Something went wrong while deleting the downloads")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}
export const DeleteDownloadedEpisode = (episodeId) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const formdata = new FormData()
        formdata.append("episode_id", episodeId) // ! was deleting the whole episode before
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/delete-downloaded-episode",
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status === 200) {
                dispatch({ type: DELETE_EPISODES_FROM_EPISODES_PER_SEASON, payload: episodeId })
            } else {
                console.error("Something went wrong while deleting the downloaded episode")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}

export const GetUpcomingShows = (limit = 15, load) => {
    return async (dispatch) => {
        const myHeaders = new Headers()
        myHeaders.append("ac", "application/json")
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + '/api/upcomingshows?limit=' + limit,
            requestOptions
        ).then((response) => {
            if(!load){
                dispatch({ type: CHANGE_STATUS, payload: response.status })
            }
            return response.json()
        }).then((json) => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_UP_COMING_SHOWS, payload: json.data })
            } else {
                console.error("Something went wrong while fetching upcoming shows")
                console.error(json)
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}


export const ShowCreation = (formData, newFormat) => {
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + store.getState().shared.userData.token)

        const formdata = new FormData()
        formdata.append('title', formData.title)
        formdata.append('language', formData.language)
        formData.category.forEach(cat => formdata.append('category[]', cat))
        formdata.append('trailer', formData.trailer)
        formdata.append('portrait_image', formData.portrait_image)
        formdata.append('landscape_image', formData.landscape_image)
        formdata.append('release_date', newFormat ? newFormat : formatDate(formData.release_date))
        formdata.append('description', formData.description)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        }

        fetch(baseURL + '/api/publisher/tvshow',
            requestOptions
        ).then(response => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: 'Show'})
                toast("Show Created")
            }
            else {
                if ((json.status_code === 500) && (json.message === 'Sorry, only one show per user allowed')) {
                    toast(json.message)
                } else{
                    toast("Show Creation Error")
                    console.error('Error adding show')
                }
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
        }).catch(error => {
            console.log('error', error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}

export const ShowEdit = (formData, showId, newFormat) => {
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + store.getState().shared.userData.token)

        const formdata = new FormData()
        formdata.append('title', formData.title)
        formdata.append('language', formData.language)
        formData.category.forEach(cat => formdata.append('category[]', cat))
        formdata.append('trailer', formData.trailer)
        formdata.append('portrait_image', formData.portrait_image)
        formdata.append('landscape_image', formData.landscape_image)
        formdata.append('release_date', newFormat ? newFormat : formatDate(formData.release_date))
        formdata.append('description', formData.description)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        }
        fetch(baseURL + `/api/publisher/tvshow/${showId}`,
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: 'Show'})
                toast("Show Edited Successfully")
            }
            else {
                toast("Show Edit Error")
                console.error('Error editing show')
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
        }).catch(error => {
            console.log('error', error)
        })
    }
}


export const fetchLanguageInfo = () => {
    return async (dispatch) =>{
        const token = store.getState().shared.userData.token
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/get-languages",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_LANGUAGE_DATA, payload: json.data})
            } else {
                console.error("Something went wrong while fetching category information")
            }
        }).catch(error => {
            console.error('error', error)
        })
    }
}
export const CreateSeason = (formData, newFormat) => {
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + store.getState().shared.userData.token)

        const formdata = new FormData();
        formdata.append('title', formData.title)
        formdata.append('tv_show', formData.tv_show)
        formdata.append('trailer', formData.trailer)
        formdata.append('portrait_image', formData.portrait_image)
        formdata.append('landscape_image', formData.landscape_image)
        formdata.append('release_date', newFormat ? newFormat : formatDate(formData.release_date))
        formdata.append('description', formData.description)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(baseURL + '/api/publisher/season/store',
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: 'Season'})
                toast("Season Created")
            }
            else {
                toast("Season Creation Error")
                console.error('Error adding season')
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
        }).catch(error => {
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            console.log('error', error)
        });
    }
}
export const CreateEpisodeDetails = (formData, selectDate, history) => {
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + store.getState().shared.userData.token)

        const formdata = new FormData();
        formdata.append('title', formData.title)
        formdata.append('tv_show', formData.tv_show)
        formdata.append('season', formData.season)
        formdata.append('episode_number', formData.episode_number)
        formdata.append('portrait_image', formData.portrait_image)
        formdata.append('landscape_image', formData.landscape_image)
        formdata.append('release_date', formatDate(selectDate))
        formdata.append('release_time', formatTime(selectDate))
        formdata.append('description', formData.description)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(baseURL + '/api/publisher/episode/store',
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                // Redirect to episode upload page
                history.push({
                    pathname: '/upload',
                    state: {
                        episodeTitle: json.data.title,
                        episodeId: json.data.id
                    }
                })
                toast("Episode Created")
            }
            else {
                if ((json.status_code === 500) && (json.message === 'Sorry, you have already uploaded an episode this week. You can upload next episode in a week.')) {
                    toast(json.message)
                } else{
                    toast("Show Creation Error")
                    console.error('Error adding show')
                }
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch(error => {
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            console.log('error', error)
        });
    }
}


export const DeleteShow = (contentId) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const token = store.getState().shared.userData.token
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        const requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + `/api/publisher/tvshow/${contentId}`,
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: 'tvshow'})
                toast(`Tvshow Deleted Successfully`)
            } else {
                console.error(`Something went wrong while Deleting tvshow with ID ${contentId}`)
                toast(`Something went wrong while Deleting tvshow`)
            }
            dispatch({ type: CHANGE_LOADER, payload: false })
            dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
        }).catch(error => {
            toast(`Something went wrong while Deleting tvshow with ID ${contentId}`)
            console.error('error', error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        })
    }
}

export const DeleteContent = (contentId, contentType) => {
    //contentType = "TvShow" || "Season" || "Episode"
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const token = store.getState().shared.userData.token
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        const requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + `/api/publisher/${contentType.toLowerCase()}/delete/${contentId}`,
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: contentType})
                toast(`${contentType} Deleted Successfully`)
            } else {
                console.error(`Something went wrong while Deleting ${contentType} with ID ${contentId}`)
                toast(`Something went wrong while Deleting ${contentType}`)
            }
            dispatch({ type: CHANGE_LOADER, payload: false })
            dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
        }).catch(error => {
            toast(`Something went wrong while Deleting ${contentType} with ID ${contentId}`)
            console.error('error', error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        })
    }
}

export const AddViewToEpisode = (episodeId) => {
    return async (dispatch) => {
        const myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)
        const formdata = new FormData()
        formdata.append("episode_id", episodeId)
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/viewed",
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (json.status_code === 200) {
                console.log(`Added 1 View to Video with ID ${episodeId}`);
            } 
        }).catch((error) => {
            console.error(error)
        })
    }
}


export const GetTVShowInfo = (showId) => {
    return async (dispatch) =>{
        const token = store.getState().shared.userData.token
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + `/api/publisher/tvshow/${showId}`,
        requestOptions
    ).then(response => {
        return response.json()
    }).then(json => {
        if (json.status_code === 200) {
            dispatch({type: CHANGE_SHOW_TO_EDIT, payload: json.data})
        } else {
            console.error("Something went wrong while fetching tv show information")
        }
    }).catch(error => {
        console.error('error', error)
    })
    }
}

export const SeasonEdit = (formData, seasonId, newFormat) => {
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + store.getState().shared.userData.token)

        const formdata = new FormData()
        formdata.append('season_id', seasonId)
        formdata.append('title', formData.title)
        formdata.append('trailer', formData.trailer)
        formdata.append('portrait_image', formData.portrait_image)
        formdata.append('landscape_image', formData.landscape_image)
        formdata.append('release_date', newFormat ? newFormat : formatDate(formData.release_date))
        formdata.append('description', formData.description)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        }
        fetch(baseURL + `/api/publisher/season/update/${formData.tv_show_id}`,
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: 'Season'})
                toast("Season Edited Successfully")
            }
            else {
                toast("Season Edit Error")
                console.error('Error editing season')
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch(error => {
            dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
            console.log('error', error)
        })
    }
}
export const fetchSeasonInfo = (seasonId) => {
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + store.getState().shared.userData.token)

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        fetch(baseURL + `/api/publisher/season/get-season/${seasonId}`,
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({type: CHANGE_SEASON_TO_EDIT, payload: json.data})
            }
            else {
                console.error('Something went wrong while fetching Season information')
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch(error => {
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
            console.log('error', error)
        })
    }
}

export const episodeEdit = (formData, episodeId, selectDate) => {
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + store.getState().shared.userData.token)

        const formdata = new FormData()
        formdata.append('episode_id', episodeId)
        formdata.append('title', formData.title)
        formdata.append('tv_show', formData.tv_show)
        formdata.append('season', formData.season)
        formdata.append('season_id', formData.season_id)
        formdata.append('episode_number', formData.episode_number)
        formdata.append('portrait_image', formData.portrait_image)
        formdata.append('landscape_image', formData.landscape_image)
        formdata.append('release_date', formatDate(selectDate))
        formdata.append('release_time', formatTime(selectDate))
        formdata.append('description', formData.description)

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        }
        fetch(baseURL + `/api/publisher/episode/update/${formData.tv_show_id}`,
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: 'Show'})
                toast("Episode Edited Successfully")
            }
            else {
                toast("Episode Edit Error")
                console.error('Error editing Episode')
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch(error => {
            console.log('error', error)
        })
    }
}
export const fetchEpisodeInfo = (episodeId) => {
    return async (dispatch) =>{
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + store.getState().shared.userData.token)

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        fetch(baseURL + `/api/publisher/episode/get-episode/${episodeId}`,
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({type: CHANGE_EPISODE_TO_EDIT, payload: json.data})
            }
            else {
                console.error('Something went wrong while fetching Episode information')
                dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch(error => {
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            dispatch({ type: REFRESH_SIDEBAR_COMPONENT, payload: ''})
            console.log('error', error)
        })
    }
}