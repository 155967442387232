import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { GetPublisherShowList } from "../../store/Actions/userActions";
import TvShowList from "./showlistComponents/TvShowsList";
import SeasonsList from "./showlistComponents/SeasonsList";
import EpisodesList from "./showlistComponents/EpisodesList";

const ShowList = ({ setSidebarComponent }) => {
  //const dispatch = useDispatch();

  const [component, setComponent] = useState("Show");
  const [newShowId, setShowId] = useState(null);
  const [newShowName, setShowName] = useState(null);
  const [newSeasonId, setSeasonId] = useState(null);
  const [newSeasonTitle, setSeasonTitle] = useState(null);
  //const { refreshSidebar } = useSelector(state => state.movie)


  // useEffect(() => {
  //   dispatch(GetPublisherShowList());
  // }, [component, refreshSidebar]);

  /*
    **setCompSwitch is passed into children to switch between Show/Season/Episode List**
    **setShowName and setShowId only change in TvShowList based on Show Title chosen**
    **setSeasonTitle only chnages in SeasonsList based on Season Title chosen**
  */
  const setCompSwitch = (params) => {
    const { string, showId, seasonId, seasonTitle, showName } = params;
    if (string?.length) {
      setComponent(string);
    }
    if (showId) {
      setShowId(showId);
    }
    if (seasonId) {
      setSeasonId(seasonId);
    }
    if (seasonTitle) {
      setSeasonTitle(seasonTitle);
    }
    if (showName) {
      setShowName(showName);
    }
  };

  switch (component) {
    case "Show":
      return (
        <TvShowList
          setSidebarComponent={setSidebarComponent}
          currentComponent={component}
          setCompSwitch={setCompSwitch}
        />
      );
    case "Season":
      return (
        <SeasonsList
          setSidebarComponent={setSidebarComponent}
          currentComponent={component}
          tvShowId={newShowId}
          showName={newShowName}
          setCompSwitch={setCompSwitch}
        />
      );
    case "Episode":
      return (
        <EpisodesList
          setSidebarComponent={setSidebarComponent}
          currentComponent={component}
          seasonTitle={newSeasonTitle}
          seasonId={newSeasonId}
          tvShowId={newShowId}
          showName={newShowName}
          setCompSwitch={setCompSwitch}
        />
      );
    default:
      break;
  }
  return <>{component}</>;
};

export default ShowList;
