import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/custom/show-detail-modal.css";
import {
  ChangeShowMovieInfoModal,
  FollowShow,
  GetAllEpisodesOfSpecificSeason,
  GetEpisodeVideos,
} from "../store/Actions/movieActions";
import { useHistory, Link } from "react-router-dom";
import { baseSharingURL, wasabiBaseURL } from '../config/baseURL'
import { DislikeShow, LikeShow } from './../store/Actions/sharedActions';
import { FacebookShareButton } from 'react-share';
import { TwitterShareButton } from 'react-share';
import { CopyLink } from './../views/backend/movie/helperComponents/CopyLink';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      key={index}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShowDetailModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { showMovieInfoModal, seasonDetail, currentSeasonEpisodes } =
    useSelector((state) => state.movie);
  const { dislikedShowsIds, likedShowsIds, followedShowsIds, showDetailEpisodesLoader } = useSelector(
    (state) => state.shared
  );

  //seasonDetail seems to be tv_shows table in DB. Seems it could contain all data of an episode/season/tvshow
  //! addCast will likely be add_casts to reflect variable from DB?
  //! As well as addCast_name to add_casts_name?
  const {
    addCast,
    // banner,
    category,
    description,
    // episode_count,
    // followers_count,
    id,
    // is_following,
    // landscape_image,
    maturity_level,
    name,
    // portrait_image,
    publisher,
    release_date,
    seasons,
    // seasons_count,
    // show_logo,
    trailer,
  } = seasonDetail;

  //! make into return statements
  let stringCategories = "";
  category?.map((item, key) => {
    const { 
      // category_id, 
      category_name 
    } = item;
    if (key === 0) stringCategories = category_name;
    else stringCategories = stringCategories + ", " + category_name;
    return null;
  });

  let stringCasts = "";
  addCast?.map((item, key) => {
    const { 
      // addCast_id, 
      addCast_name 
    } = item;
    if (key === 0) stringCasts = addCast_name;
    else stringCasts = stringCasts + ", " + addCast_name;
    return null;
  });

  const [value, setValue] = React.useState(0);
  const handleSwitchPage = () => {
    dispatch(ChangeShowMovieInfoModal(false));
    history.push(`/details/${name.split(" ").join("-").toLowerCase()}-${id}`);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    let seasonId = seasons[newValue].season_id;
    dispatch(GetAllEpisodesOfSpecificSeason(seasonId));
  };
  useEffect(() => {
    setValue(0);
  }, [showMovieInfoModal]);

  function RenderSeasonTabs() {
    return seasons?.map((item, key) => {
      const { season_name } = item;
      return <Tab label={season_name} {...a11yProps(key)} key={key} />;
    });
  }

  function RenderEpisodes() {
    return currentSeasonEpisodes?.map((item, key) => {
      const {
        // created_at,
        id,
        landscape_image,
        // portrait_image,
        release_date,
        // status,
        title,
        // video,
        description,
      } = item;
      return (
        <div
          className="episode-card-wide red-border"
          key={key}
          onClick={() => {
            dispatch(ChangeShowMovieInfoModal(false));
            dispatch(GetEpisodeVideos(id, true, true));
          }}
        >
          <div className="episode-image-div">
            <img
              alt="episode card"
              className="episode-image"
              src={wasabiBaseURL + landscape_image}
            />
          </div>
          <div className="episode-content red-border">
            <div className="episode-content-top red-border">
              <div className="episode-name-div red-border">
                <span className="episode-name">{title}</span>
              </div>
              <div className="episode-date-div red-border">
                <span className="episode-date">
                  {moment(new Date(release_date)).format("MMM Do YY")}
                </span>
              </div>
            </div>
            <div className="episode-content-bottom red-border">
              <span className="episode-desc">{description}</span>
            </div>
          </div>
          {/* <Divider /> */}
        </div>
      );
    });
  }
  function RenderSeasonTabPannels() {
    return seasons?.map((item, key) => {
      // const { name, image } = item;
      return (
        <TabPanel value={value} index={key} key={key}>
          {!showDetailEpisodesLoader ? (
            <>
              {currentSeasonEpisodes.length !== 0 ? (
                RenderEpisodes()
              ) : (
                <div>
                  <span
                    style={{ fontSize: 18, fontWeight: "bold", color: "gray" }}
                  >
                    No Episodes
                  </span>
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader type="TailSpin" color="#EDB500" height={30} width={30} />
            </div>
          )}
        </TabPanel>
      );
    });
  }

  return (
    <Modal
      size="lg"
      show={showMovieInfoModal}
      onHide={() => dispatch(ChangeShowMovieInfoModal(false))}
      dialogClassName="modal-90w show-detail-modal"
      aria-labelledby="example-custom-modal-styling-title modal-container"
    >
      <video
        controls
        className="w-100"
        id="modal-video"
        style={{objectFit: "cover" }}
      >
        <source src={trailer} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>

      <Modal.Body className="modal-body">
        <div
          style={{ paddingLeft: 10, display: "flex", alignItems: "center" }}
          className="span-tags red-border"
          data-animation-in="fadeInUp"
          data-delay-in="1"
        >
          <span
            className="badge badge-secondary p-2"
            style={{ marginLeft: "5px" }}
          >
            {maturity_level}
          </span>
          <span className="ml-3">
            {moment(new Date(release_date)).format("MMM Do YY")}
          </span>
          <ul className="list-inline p-0 mt-4 share-icons music-play-lists ml-4">
                <li
                  onClick={() => {
                    if (followedShowsIds.includes(id)) {
                      dispatch(FollowShow(id, false));
                    } else {
                      dispatch(FollowShow(id, true));
                    }
                  }}
                >
                  <span>
                    {followedShowsIds.includes(id) ? (
                      <i className="custom-icon ri-check-line" />
                    ) : (
                      <i className="custom-icon ri-add-line" />
                    )}
                  </span>
                </li>
                <li className="share">
                  <span className="">
                    <i className="custom-icon ri-heart-fill"></i>
                  </span>
                  <div className="share-box">
                    <div className="d-flex align-items-center">
                      {likedShowsIds.includes(id) ? (
                        <i
                          onClick={() => dispatch(LikeShow(id))}
                          className="hover-icon ri-thumb-up-fill"
                        />
                      ) : (
                        <i
                          onClick={() => dispatch(LikeShow(id))}
                          className="hover-icon ri-thumb-up-line"
                        />
                      )}
                      {dislikedShowsIds.includes(id) ? (
                        <i
                          onClick={() => dispatch(DislikeShow(id))}
                          className="hover-icon ri-thumb-down-fill"
                        />
                      ) : (
                        <i
                          onClick={() => dispatch(DislikeShow(id))}
                          className="hover-icon ri-thumb-down-line"
                        />
                      )}
                    </div>
                  </div>
                </li>
                <li className="share">
                  <span>
                    <i className="custom-icon ri-share-fill"></i>
                  </span>
                  <div className="share-box">
                    <div className="d-flex align-items-center">
                      <FacebookShareButton
                        url={baseSharingURL + id}
                        title={name}
                      >
                        <i className="ri-facebook-fill"></i>
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={baseSharingURL + id}
                        title={name}
                        className="share-ico"
                      >
                        <i className="ri-twitter-fill"></i>
                      </TwitterShareButton>
                      <Link to="#" 
                        onClick={() => CopyLink(id)} 
                        className="share-ico">
                        <i className="ri-links-fill"></i>
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
        </div>
        <div className="modal-text red-border">
          <div className="text-left" style={{ marginRight: 10 }}>
            <span className="modal-show-desc">{description}</span>
          </div>
          <div className="text-align-right modal-show-cast red-border">
            <p className="modal-p-tag">
              <span className="span-props">Cast :</span>
              {stringCasts}
            </p>
            <p className="modal-p-tag">
              <span className="span-props">Genres :</span> {stringCategories}
            </p>
            <p className="modal-p-tag">
              <span className="span-props">Publisher :</span>{" "}
              {publisher?.publisher_name}
            </p>
            <span
              onClick={handleSwitchPage}
              className="btn btn-link font-xsmall"
            >
              More Details
            </span>
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={value}
              onChange={handleChange}
              aria-label="secondary tabs example"
            >
              {seasons?.length !== 0 ? (
                RenderSeasonTabs()
              ) : (
                <div>
                  <span
                    style={{ fontSize: 18, fontWeight: "bold", color: "gray" }}
                  >
                    No Seasons
                  </span>
                </div>
              )}
            </Tabs>
          </Box>
          {RenderSeasonTabPannels()}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default ShowDetailModal;
