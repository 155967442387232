import { gsap } from 'gsap'
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import SwiperCore, { EffectFade, Navigation, Pagination, Thumbs } from 'swiper'
import 'swiper/swiper-bundle.css'
import { GetFollowingShows } from '../../../store/Actions/movieActions'
import RenderLikedShows from './RenderLikedShows'
import { VerifyActiveSubscription } from './../../../store/Actions/userActions';


SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)

            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)

            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)

            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)

            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)

            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)

            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)

            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')

            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }

            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        gsap.from(elem, setOption)

    },

    onEnd: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        gsap.to(elem, setOption)

    },

    onStartEnd: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        const setEndOption = gsapAnimate.getData(elem)

        setEndOption.opacity = 1

        setEndOption.x = 0

        setEndOption.y = 0

        setEndOption.rotate = 0

        setEndOption.scale = 1

        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}

const Following = () => {
    const dispatch = useDispatch();
    const { liked } = useSelector((state) => state.movie);

    useEffect(() => {
        dispatch(VerifyActiveSubscription())
        dispatch(GetFollowingShows())
    }, [dispatch])

    return (
        <>
            <div className="main-content">
                {liked.length > 0 ?
                    <>
                        <Container fluid className="custom-container heading-container">
                            <h3 className="main-title main-heading"> {"Following Shows"}</h3>
                        </Container>
                        <section id="iq-favorites vertial-cards-section">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="favorites-contetns vertial-cards-div">
                                            <ul className="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction">
                                                <RenderLikedShows likedList={liked}/>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                    :
                    <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', color: 'gray' }}>
                            You haven't followed any shows yet!
                        </span>
                    </div>
                }
            </div>
        </>
    )
}


export default Following;