import React, {useEffect, useState} from 'react'
import { Table } from 'react-bootstrap'
import PublisherCard from './PublisherCard'
import { GetWalletPayments } from './../../store/Actions/userActions';
import { useDispatch, useSelector } from 'react-redux';

const WalletPayments = () => {
    
    const { walletPayments } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    const handleChangePage = (page) => {
        setCurrentPage(page);
    }

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(0); // Reset to first page when items per page changes
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(0); // Reset to first page when search term changes
    }

    // Filter shows based on search term
    // Slice shows based on current page and items per page
    // Calculate total pages
    const filteredShows = walletPayments?.filter(payment => payment.id.toLowerCase().includes(searchTerm.toLowerCase()));
    const totalPages = Math.ceil(filteredShows?.length / itemsPerPage);
    const currentItems = filteredShows?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    
    useEffect(() => {
        dispatch(GetWalletPayments())
    }, [dispatch]);
    return (
        <PublisherCard 
            title={ `Wallet Payments` }
            totalEntries={filteredShows?.length}
            currentPage={currentPage}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            itemsPerPage={itemsPerPage}
            handleChangeItemsPerPage={handleChangeItemsPerPage}
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
        >
            <Table className='dataTable'>
                <thead>
                    <tr>
                        <th tabIndex={0} aria-label='Payment Id: activate to sort column ascending' style={{ width: '175px', paddingRight: '30px' }}>Payment Id</th>
                        <th tabIndex={0} aria-label='Publisher Name: activate to sort column ascending' style={{ width: '240px', paddingRight: '30px' }}>Publisher Name</th>
                        <th tabIndex={0} aria-label='Amount: activate to sort column ascending' style={{ width: '138px', paddingRight: '30px' }}>Amount</th>
                        <th tabIndex={0} aria-label='Payment Mode: activate to sort column ascending' style={{ width: '221px', paddingRight: '30px' }}>Payment Mode</th>
                        <th tabIndex={0} aria-label='Transaction Type: activate to sort column ascending' style={{ width: '268px', paddingRight: '30px' }}>Transaction Type</th>
                        <th tabIndex={0} aria-label='Payment Date: activate to sort column ascending' style={{ width: '212px', paddingRight: '30px' }}>Payment Date</th>
                    </tr>
                </thead>
                <tbody>
                {currentItems.map((payment) => {
                        const date = payment.created_at
                        const [fullDate] = date.split('T');
                        const [year, month, day] = fullDate.split('-')
                        return (
                            <tr key={payment.id}>
                                <td>{payment.id}</td>
                                <td>
                                    {payment.user.name}
                                </td>
                                <td>{payment.amount}</td>
                                <td>{payment.mode}</td>
                                <td>{payment.type}</td>
                                <td>{`${month}/${day}/${year}`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </PublisherCard>
    )
}

export default WalletPayments
