import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import moment from "moment";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { baseSharingURL } from "../../../../config/baseURL";
import { useDispatch } from "react-redux";
import {
  FollowShow,
  GetShowDetails,
} from "../../../../store/Actions/movieActions";
import { DislikeShow, LikeShow } from "../../../../store/Actions/sharedActions";
import { CopyLink } from "./CopyLink";
import { useState } from "react";

export const RenderSlides = ({showList, slideId, dislikedShowsIds, likedShowsIds, followedShowsIds, userData}) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  

  const constructUrl = (name, id) => {
    return `${baseSharingURL}${name.split(" ").join("-").toLowerCase()}-${id}`;
  }


  return (
    <div>
      <div id={`prev${slideId}`} className="swiper-button swiper-button-prev">
        <i className="fa fa-chevron-left"></i>
      </div>
      <div id={`next${slideId}`} className="swiper-button swiper-button-next">
        <i className="fa fa-chevron-right"></i>
      </div>
      <Swiper
        spaceBetween={10}
        navigation={{
          prevEl: `#prev${slideId}`,
          nextEl: `#next${slideId}`,
        }}
        loop={true}
        breakpoints={{
          320: { slidesPerView: 2 },
          550: { slidesPerView: 4 },
          991: { slidesPerView: 5 },
          1400: { slidesPerView: 6 },
        }}
        // centeredSlides={true}
        className="favorites-slider list-inline row p-0 m-0 details-swiper-container"
      >
        {showList.map((show, key) => (
          <SwiperSlide className="slide-item details-swiper main-swiper-slides" key={key}>
            <div className="block-images1 block-images details-swiper">
              <div className="img-box">
                <img
                  src={show.portrait_image}
                  onClick={() => dispatch(GetShowDetails(show.id))}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="block-description">
                <h6 className="iq-title">
                  <Link to="#" onClick={() => dispatch(GetShowDetails(show.id))}>{show.name}</Link>
                </h6>
                <div className="movie-time d-flex align-items-center my-2">
                  <div className="badge badge-secondary p-1 mr-2">
                    {show.maturity_level}
                  </div>
                  <span className="text-white">
                    {moment(show.release_date).format("MMM Do YY")}
                  </span>
                </div>
              </div>
              <div className="block-social-info">
                <ul className="list-inline p-0 m-0 music-play-lists">
                  <li className="share">
                    <span>
                      <i className="ri-share-fill"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center">
                        <FacebookShareButton
                          url={(constructUrl(show.name, show.id))}
                          hashtag="#multitude"
                          quote="Share this show with your friends"
                        >
                          <i className="ri-facebook-fill"></i>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={(constructUrl(show.name, show.id))}
                          title={show.name}
                          hashtags={["multitude"]}
                          className="share-ico"
                        >
                          <i className="ri-twitter-fill"></i>
                        </TwitterShareButton>
                        <Link
                          to="#"
                          onClick={() => {CopyLink(show.name, show.id); setCopied(!copied)}}
                          className="share-ico"
                        >
                          <i className={copied ? "ri-links-fill-copied ri-links-fill" : "ri-links-fill"}></i>
                        </Link>
                      </div>
                    </div>
                  </li>
                  {userData?.email && 
                    <><li className="share">
                      <span>
                        <i className="ri-heart-fill"></i>
                      </span>
                      <div className="share-box">
                        <div className="d-flex align-items-center">
                          {likedShowsIds.includes(show.id) ? (
                            <i
                              onClick={() => dispatch(LikeShow(show.id))}
                              className="hover-icon ri-thumb-up-fill" />
                          ) : (
                            <i
                              onClick={() => dispatch(LikeShow(show.id))}
                              className="hover-icon ri-thumb-up-line" />
                          )}
                          {dislikedShowsIds.includes(show.id) ? (
                            <i
                              onClick={() => dispatch(DislikeShow(show.id))}
                              className="hover-icon ri-thumb-down-fill" />
                          ) : (
                            <i
                              onClick={() => dispatch(DislikeShow(show.id))}
                              className="hover-icon ri-thumb-down-line" />
                          )}
                        </div>
                      </div>
                      {/* //! there is no like counter in the DB <span className="count-box">19+</span> */}
                    </li><li
                      onClick={() => {
                        if (followedShowsIds.includes(show.id)) {
                          dispatch(FollowShow(show.id, false));
                        } else {
                          dispatch(FollowShow(show.id, true));
                        }
                      } }
                    >
                        <span>
                          {followedShowsIds.includes(show.id) ? (
                            <i className="ri-check-line" />
                          ) : (
                            <i className="ri-add-line" />
                          )}
                        </span>
                      </li></>  
                  }
                </ul>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
