import React, { useEffect, useState, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  PlayNow,
  GetSimilarShowsByCategory,
  GetUpcomingShows,
} from "../../../store/Actions/movieActions";
import { RenderGenres } from "./helperComponents/RenderGenres";
import { RenderCast } from "./helperComponents/RenderCast";
import { VerifyActiveSubscription } from './../../../store/Actions/userActions';
import { FollowedShows, GetShowDetails } from './../../../store/Actions/movieActions';
import SimilarShows from "./detailsHelperComponents/SimilarShows";
import UpcomingShows from "./detailsHelperComponents/UpcomingShows";
import FollowShowIcon from "./detailsHelperComponents/FollowShowIcon";
import LikeShowIcon from "./detailsHelperComponents/LikeShowIcon";
import ShareIcon from "./detailsHelperComponents/ShareIcon";

// install Swiper modules
SwiperCore.use([Navigation]);

//? should take a movie that is passed in as an argument when the movie is clicked?
const AddMovie = () => {
  const dispatch = useDispatch();
  const { movieUrl } = useParams();
  const {
    seasonDetail: tv_showsData,
    similarShows,
    upComingShows,
  } = useSelector((state) => state.movie);
  const { dislikedShowsIds, likedShowsIds, followedShowsIds, userData } = useSelector(
    (state) => state.shared
  );
  const [showDiv, setShowDiv] = useState()

  //seasonDetail seems to be tv_shows table in DB. Seems it could contain all data of an episode/season/tvshow
  const {
    id,
    name,
    description,
    maturity_level,
    release_date,
    category,
    duration,
    addCast,
    // seasons_count,
    // episode_count,
    // followers_count,
    // is_following,
    // portrait_image,
    // banner,
    // show_logo,
    // trailer,
    // landscape_image,
    // seasons,
    // episode,
    // title,
    // publisher,
  } = tv_showsData;

  // Memoize the category ID to avoid unnecessary re-renders
  const categoryId = useMemo(() => category ? category[0].category_id : null, [category]);
  
  // Extract movieId from movieUrl
  const movieId = useMemo(() => movieUrl ? movieUrl.split("-").pop() : null, [movieUrl]);

  // Fetch show details when movieId changes
  useEffect(() => {
    if (movieUrl) {
      dispatch(GetShowDetails(movieId, false, true, true));
    }
  }, [dispatch, movieUrl, movieId]);

  // Fetch similar shows when category changes
  useEffect(() => {
    if (categoryId) {
      dispatch(GetSimilarShowsByCategory(categoryId, 15, true));
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
    dispatch(GetUpcomingShows(15, true));
    if (userData?.is_subscribed === "true") {
      dispatch(VerifyActiveSubscription());
      dispatch(FollowedShows());
    }
  }, [dispatch, userData?.is_subscribed]);

  // Handle show state and cleanup
  useEffect(() => {
    const timer = setTimeout(() => setShowDiv(true), 4000);
    return () => clearTimeout(timer);
  }, []);

  return (

  <div className="main-content movi">
    {name ?
      <>
        {/* <Navbar style={{ flexWrap: 'nowrap' }} className='custom-navbar p-0' expand="lg" >
          <Navbar.Brand className={`${location.pathname === "/publisher" ? "invisible" : "navbar-brand"} `} href="/">
              <img className="img-fluid logo" src={logo} alt="multitude" />
          </Navbar.Brand>
        </Navbar> */}
        <section className="movie-detail container-fluid">
          <Row>
            <Col lg="12">
              <div className="trending-info g-border">
                <h1 className="trending-text big-title text-uppercase mt-0">
                  {name ? name : "No Title Found For This Video"}
                </h1>
                {category?.length ? (
                  <RenderGenres category={category}/>
                ) : (
                  <ul className="p-0 list-inline d-flex align-items-center movie-content">
                    <li className="text-white">Error</li>
                    <li className="text-white">Loading</li>
                    <li className="text-white">Genres</li>
                  </ul>
                )}
                {addCast?.length ? <RenderCast addCast={addCast}/> : "No Cast Found"}
                <div className="d-flex align-items-center text-white text-detail">
                  <span className="badge badge-secondary p-3">
                    {maturity_level ? maturity_level : "No Rating Found"}
                  </span>
                  <span className="ml-3">
                    {duration ? duration : "No Video Duration Found"}
                  </span>
                  <span className="trending-year">
                    {release_date
                      ? moment(release_date).format("MMM Do YY")
                      : "No Release Date Found"}
                  </span>
                </div>
                <div className="hover-buttons">
                  <div
                    className="btn btn-hover iq-button font-small iq-button"
                    onClick={() => {
                      if (userData?.is_subscribed === true) {
                        dispatch(PlayNow(id));
                      } else {
                        if (userData?.email) {
                          window.location.href = "/plan-details";
                        } else {
                          window.location.href = "/sign-up";
                        }
                      }
                    }}
                    style={{ color: "black" }}
                  >
                    <i className="fa fa-play mr-2" aria-hidden="true"></i>
                    Play Now
                  </div>
                </div>
                <p className="trending-dec w-100 mb-0">
                  {description ? description : "No Description Found"}
                </p>
                <ul className="list-inline p-0 mt-4 share-icons music-play-lists">
                  {userData?.is_subscribed === true && 
                  <>
                    <FollowShowIcon followedShowsIds={followedShowsIds} id={id}/>
                    <LikeShowIcon likedShowsIds={likedShowsIds} dislikedShowsIds={dislikedShowsIds} id={id}/>
                  </>
                  }
                  <ShareIcon name={name} id={id} />
                </ul>
              </div>
            </Col>
          </Row>
        </section>
        <SimilarShows 
         similarShows={similarShows}
         dislikedShowsIds={dislikedShowsIds}
         likedShowsIds={likedShowsIds}
         followedShowsIds={followedShowsIds}
         userData={userData}
        />
        <UpcomingShows 
        upComingShows={upComingShows}
        dislikedShowsIds={dislikedShowsIds}
        likedShowsIds={likedShowsIds}
        followedShowsIds={followedShowsIds}
        userData={userData}
        />
      </>
    : showDiv && "No Show Found"
    }
  </div>
  );
};
export default AddMovie;
