import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { RenderSlides } from "../helperComponents/RenderSlides";
import { useState, useEffect } from "react";
import Loader from "../../../../router/Loader";


const SimilarShows = ({ similarShows, dislikedShowsIds, likedShowsIds, followedShowsIds, userData}) => {
    
    const [showDiv, setShowDiv] = useState()

    // Handle show state and cleanup
    useEffect(() => {
      const timer = setTimeout(() => setShowDiv(true), 7000);
      return () => clearTimeout(timer);
    }, []);
    return ( 
        <section id="iq-favorites" className="s-margin iq-rtl-direction">
          <Container fluid>
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="main-title">
                    <Link to="#">More Like This</Link>
                  </h4>
                </div>
                <div id="favorites-contens">
                  {similarShows?.length
                    ? <RenderSlides dislikedShowsIds={dislikedShowsIds} likedShowsIds={likedShowsIds} followedShowsIds={followedShowsIds} showList={similarShows} slideId={1} userData={userData}/>
                    : showDiv ? "No Similar Shows Found" : <Loader noPush={true}/>}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
     );
}
 
export default SimilarShows;