import {
    ADD_DOWNLOADED_EPISODES_IDS,
    CHANGE_CONTINUE_WATCHING,
    CHANGE_CURRENT_SEASON_EPISODES,
    CHANGE_DOWNLOADED_EPISODES_IDS,
    CHANGE_DOWNLOADED_SEASONS,
    CHANGE_EPISODES_PER_SEASON,
    CHANGE_FULL_SCREEN_EPISODE,
    CHANGE_GENRE,
    CHANGE_GENRE_PICKER_SWAP_PANNEL,
    CHANGE_GENRE_SECTIONS,
    CHANGE_LIKED,
    CHANGE_MOST_TREDING_SHOWS,
    CHANGE_NEW_EPISODES,
    CHANGE_PAUSED_TV_SHOWS,
    CHANGE_POPULAR_HORROR_SHOWS,
    CHANGE_POPULAR_MOVIES,
    CHANGE_POPULAR_SEARCHES,
    CHANGE_POSTERS,
    CHANGE_PUBLISHER_DETAIL,
    CHANGE_PUBLISHER_SHOWS,
    CHANGE_RECOMMENDED_SHOWS,
    CHANGE_SEARCHED_SHOWS,
    CHANGE_SEARCH_QUERY,
    CHANGE_SEASON_DETAIL,
    CHANGE_SELECTED_CATEGORY,
    CHANGE_SELECTED_EPISODE_AND_CURRENT_TIME_FOR_FULL_SCREEN,
    CHANGE_SELECTED_EPISODE_CURRENT_TIME_FOR_FULL_SCREEN,
    CHANGE_SELECTED_EPISODE_FOR_FULL_SCREEN,
    CHANGE_SELECTED_PUBLISHER_ID,
    CHANGE_SHOWS_BY_CATEGORY,
    CHANGE_SHOW_MOVIE_INFO_MODAL,
    CHANGE_SIMILAR_SHOWS,
    CHANGE_TRENDING_SHOWS,
    CHANGE_UP_COMING_SHOWS,
    CURRENT_CATEGORY,
    CURRENT_VIDEO,
    DELETE_ALL_DOWNLOADS,
    DELETE_EPISODES_FROM_EPISODES_PER_SEASON,
    SEARCHED_QUERY,
    TOGGLE_IS_FOLLOWING_IN_SHOW_DETAILS,
    CHANGE_LANGUAGE_DATA,
    CHANGE_PROGRESS_EVENT,
    CHANGE_SHOW_TO_EDIT,
    CHANGE_SEASON_TO_EDIT,
    CHANGE_EPISODE_TO_EDIT,
    CHANGE_EDIT_FORM_DATA,
    REFRESH_SIDEBAR_COMPONENT,
    CHANGE_UPLOAD_FINAL,
    CHUNK_UPLOAD_COMPLETE,
    CHANGE_UPLOAD_PROGRESS,
    SET_RESUME_DATA,
    PAUSE_UPLOAD,
    RESUME_UPLOAD,
    CANCEL_UPLOAD,
} from '../Actions/types'

const initialState = {
    progressEvent: {
        uploadedChunks: 0,
        uploadProgress: 0,
        totalChunks: 0,
    },
    uploadProgress: {},


    currentCategory: '',

    currentVideoName: '',
    showMovieInfoModal: false,
    showGenrePickerPannel: false,
    searchedQuery: '',

    continueWatching: [],
    trendingShows: [],
    mostTredingShows: [],
    recommendedShows: [],
    genreSections: [],
    popularMovies: [],
    popularSearches: [],
    searchedShows: [],
    upComingShows: [],
    popularHorrorshows: [],
    newEpisodes: [],

    liked: [],
    popularShows: [],
    bestOfInternationalShows: [],
    seasons: [
        {
            name: "Season 1",
            image: require('../../assets/images/custom/landscape-movies/1.jpg').default,
            episodes: [
                {
                    image: require('../../assets/images/custom/landscape-movies/1.jpg').default,
                    name: 'Looper',
                    duration: "45 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/2.jpg').default,
                    name: 'Reddic',
                    duration: "60 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/3.jpg').default,
                    name: 'Wolves',
                    duration: "34 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/4.jpg').default,
                    name: 'Alladin',
                    duration: "45 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/5.jpg').default,
                    name: 'Pirates Of Cariban',
                    duration: "56 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
            ]
        },
        {
            name: "Season 2",
            image: require('../../assets/images/custom/landscape-movies/6.jpg').default,
            episodes: [
                {
                    image: require('../../assets/images/custom/landscape-movies/6.jpg').default,
                    name: 'Looper',
                    duration: "45 min",
                    desc: ""
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/7.jpg').default,
                    name: 'Reddic',
                    duration: "60 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/8.jpg').default,
                    name: 'Wolves',
                    duration: "34 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/9.jpg').default,
                    name: 'Alladin',
                    duration: "45 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/10.jpg').default,
                    name: 'Pirates Of Cariban',
                    duration: "56 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/11.jpg').default,
                    name: 'Pirates Of Cariban',
                    duration: "56 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
            ]
        },
        {
            name: "Season 3",
            image: require('../../assets/images/custom/landscape-movies/4.jpg').default,
            episodes: [
                {
                    image: require('../../assets/images/custom/landscape-movies/4.jpg').default,
                    name: 'Looper',
                    duration: "45 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/3.jpg').default,
                    name: 'Reddic',
                    duration: "60 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/2.jpg').default,
                    name: 'Wolves',
                    duration: "34 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/1.jpg').default,
                    name: 'Alladin',
                    duration: "45 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
            ]
        },
        {
            name: "Season 4",
            image: require('../../assets/images/custom/landscape-movies/11.jpg').default,
            episodes: [
                {
                    image: require('../../assets/images/custom/landscape-movies/11.jpg').default,
                    name: 'Looper',
                    duration: "45 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/12.jpg').default,
                    name: 'Reddic',
                    duration: "60 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
                {
                    image: require('../../assets/images/custom/landscape-movies/13.jpg').default,
                    name: 'Wolves',
                    duration: "34 min",
                    desc: "In the near future, the mob sends their victims back in time to get them executed by the loopers. Joe, one such looper, finds himself to be the victim of his own actions."
                },
            ]
        },
    ],
    similarShows: [],
    genre: [],
    seasonDetail: {},

    selectedCategoryId: 0,
    selectedCategory: '',
    showsByCategory: [],

    searchQuery: '',
    selectedGenre: 'Action',

    selectedShowId: 0,
    selectedShowName: '',
    selectedShowReleaseDate: new Date(),
    seletedShowMaturityLevel: 'E',
    selectedShowDescription: '',
    selectedShowPortraitImage: '',

    currentSeasonEpisodes: [],

    selectedPublisherId: 0,

    selectedEpisodeForFullScreen: 0,
    selectedEpisodeCurrentTimeForFullScreen: 0.0,
    showToEdit: {},
    seasonToEdit: {},
    episodeToEdit: {},
    fullScreenEpisode: {
        id: 0,
        landscape_image: null,
        portrait_image: null,
        title: null,
        videos: [],
    },

    pausedTvShows: [],

    downloadedEpisodesIds: [],

    posters: {},
    refreshSidebar: '',
    languages: [],
    editFormData: {},
    uploadIsFinal: false,
    resumeData: null, // Add this to store resume information
    isPaused: false,
    isCancelled: false,
}

const movieReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PAUSE_UPLOAD:
            return { ...state, isPaused: true };
        case RESUME_UPLOAD:
            return { ...state, isPaused: false };
        case CANCEL_UPLOAD:
            return { ...state, isCancelled: true };
        case SET_RESUME_DATA:
            return {
              ...state,
              resumeData: payload
            };
        case CHANGE_UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: payload
            }
        case CHUNK_UPLOAD_COMPLETE:
            return {

                ...state,
                progressEvent: {
                    ...state.progressEvent,
                    uploadedChunks: state.progressEvent.uploadedChunks + 1,
                    uploadProgress: ((state.progressEvent.uploadedChunks + 1) / state.progressEvent.totalChunks) * 100,
                    chunkComplete: true
                }
            };
        case REFRESH_SIDEBAR_COMPONENT:
            return {
                ...state,
                refreshSidebar: payload
            }
        case CHANGE_UPLOAD_FINAL:
            return {
                ...state,
                uploadIsFinal: payload
            }
        case CHANGE_EDIT_FORM_DATA:
            return {
                ...state,
                editFormData: payload
            }
        case CHANGE_EPISODE_TO_EDIT:
            return {
                ...state,
                episodeToEdit: payload
            }
        case CHANGE_SEASON_TO_EDIT:
            return {
                ...state,
                seasonToEdit: payload
            }
        case CHANGE_SHOW_TO_EDIT:
            return {
                ...state,
                showToEdit: payload
            }
        case CHANGE_PROGRESS_EVENT:
            return {
                ...state,
                progressEvent: payload
            }
        case CHANGE_POPULAR_HORROR_SHOWS:
            return {
                ...state,
                popularHorrorshows: payload
            }
        case CHANGE_UP_COMING_SHOWS:
            return {
                ...state,
                upComingShows: payload
            }
        case CHANGE_CONTINUE_WATCHING:
            return {
                ...state,
                continueWatching: payload
            }
        case DELETE_ALL_DOWNLOADS:
            return {
                ...state,
                downloadedSeasons: [],
                episodesPerSeason: [],
            }
        case CHANGE_SEARCHED_SHOWS:
            return {
                ...state,
                searchedShows: payload
            }
        case CHANGE_POPULAR_SEARCHES:
            return {
                ...state,
                popularSearches: payload
            }
        case CHANGE_MOST_TREDING_SHOWS:
            return {
                ...state,
                mostTredingShows: payload
            }
        case CHANGE_POSTERS:
            return {
                ...state,
                posters: payload
            }
        case ADD_DOWNLOADED_EPISODES_IDS:
            return {
                ...state,
                downloadedEpisodesIds: [payload, ...state.downloadedEpisodesIds]
            }
        case CHANGE_DOWNLOADED_EPISODES_IDS:
            var downloadedEpisodesIdsTemp = []
            payload.map(item => downloadedEpisodesIdsTemp.push(item.episode_id))
            return {
                ...state,
                downloadedEpisodesIds: downloadedEpisodesIdsTemp
            }
        case DELETE_EPISODES_FROM_EPISODES_PER_SEASON:
            return {
                ...state,
                episodesPerSeason: state.episodesPerSeason.filter(obj => obj.episode_id !== payload),
            }
        case CHANGE_EPISODES_PER_SEASON:
            return {
                ...state,
                episodesPerSeason: payload,
            }
        case CHANGE_DOWNLOADED_SEASONS:
            return {
                ...state,
                downloadedSeasons: payload,
            }
        case CHANGE_PUBLISHER_DETAIL:
            return {
                ...state,
                publisherDetail: payload,
            }
        case TOGGLE_IS_FOLLOWING_IN_SHOW_DETAILS:
            return {
                ...state,
                seasonDetail: {
                    ...state.seasonDetail,
                    is_following: !state.seasonDetail.is_following
                }
            }
        case CHANGE_SELECTED_EPISODE_AND_CURRENT_TIME_FOR_FULL_SCREEN:
            return {
                ...state,
                selectedEpisodeCurrentTimeForFullScreen: payload.pause_time,
                selectedEpisodeForFullScreen: payload.episode_id,
            }
        case CHANGE_SELECTED_EPISODE_CURRENT_TIME_FOR_FULL_SCREEN:
            return {
                ...state,
                selectedEpisodeCurrentTimeForFullScreen: payload
            }
        case CHANGE_PAUSED_TV_SHOWS:
            return {
                ...state,
                pausedTvShows: payload
            }
        case CHANGE_RECOMMENDED_SHOWS:
            return {
                ...state,
                recommendedShows: payload
            }
        case CHANGE_TRENDING_SHOWS:
            return {
                ...state,
                trendingShows: payload
            }
        case CHANGE_SELECTED_EPISODE_FOR_FULL_SCREEN:
            return {
                ...state,
                selectedEpisodeForFullScreen: payload
            }
        case CHANGE_FULL_SCREEN_EPISODE:
            return {
                ...state,
                fullScreenEpisode:
                    payload != null
                        ?
                        payload
                        :
                        {
                            id: 0,
                            landscape_image: null,
                            portrait_image: null,
                            title: null,
                            videos: [],
                        },

            }
        case CHANGE_POPULAR_MOVIES:
            return {
                ...state,
                popularMovies: payload
            }
        case CHANGE_PUBLISHER_SHOWS:
            return {
                ...state,
                publisherShows: payload
            }
        case CHANGE_SELECTED_PUBLISHER_ID:
            return {
                ...state,
                selectedPublisherId: payload
            }
        case CHANGE_CURRENT_SEASON_EPISODES:
            return {
                ...state,
                currentSeasonEpisodes: payload
            }
        case CHANGE_SEASON_DETAIL:
            return {
                ...state,
                seasonDetail: payload
            }
        case CHANGE_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: payload.name,
                selectedCategoryId: payload.id
            }
        case CHANGE_SHOWS_BY_CATEGORY:
            return {
                ...state,
                showsByCategory: payload
            }
        case CHANGE_NEW_EPISODES:
            return {
                ...state,
                newEpisodes: payload
            }
        case CHANGE_LIKED:
            return {
                ...state,
                liked: payload
            }
        case CHANGE_GENRE_SECTIONS:
            return {
                ...state,
                genreSections: payload
            }
        case CURRENT_CATEGORY: {
            return {
                ...state,
                currentCategory: payload
            }
        }

        case CURRENT_VIDEO: {
            return {
                ...state,
                currentVideoName: payload
            }
        }
        // currentVideoName
        case SEARCHED_QUERY: {
            return {
                ...state,
                searchQuery: payload
            }
        }
        case CHANGE_GENRE:
            return {
                ...state,
                genre: payload
            }
        case CHANGE_SHOW_MOVIE_INFO_MODAL:
            return {
                ...state,
                showMovieInfoModal: payload,
            }
        case CHANGE_GENRE_PICKER_SWAP_PANNEL:
            return {
                ...state,
                showGenrePickerPannel: payload
            }
        case CHANGE_SEARCH_QUERY:
            return {
                ...state,
                searchedQuery: payload
            }
        case CHANGE_SIMILAR_SHOWS:
            return {
                ...state,
                similarShows: payload
            }
        case CHANGE_LANGUAGE_DATA:
            return {
                ...state,
                languages: payload
            }
        case 'SET_IS_PAUSED':
            return {
                ...state,
                isPaused: payload
            }
        default:
            return state;
    }
}

export default movieReducer;