import { Divider, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/signIn.css';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeLoader, ChangeUserData, VerifyActiveSubscription } from './../../../../store/Actions/userActions';
import { loadStripe } from '@stripe/stripe-js';
import ContinueToPaymentLink from './ContinueToPaymentLink';
import { useState } from 'react';
import { 
    Elements, 
    // useStripe 
} from '@stripe/react-stripe-js';
import { 
    publicKey,
    // pricePlan, 
    // testPricePlan 
} from './../../../../config/apiKeys';
import Loader from '../../../../router/Loader';
import store from '../../../../store';
// import ComputerIcon from '@mui/icons-material/Computer';
// import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
// import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
// import ButtonWrapper from './Components/ButtonWrapper';

function NativePlanDetails() {
    const matches = useMediaQuery('(min-width:768px)');
    const {userData} = useSelector((state) => state.shared)
    const dispatch = useDispatch()
    const [stripePromise] = useState(() => loadStripe(publicKey))
    const [isTimerFinished, setIsTimerFinished] = useState(false);
    
    useEffect(() => {
        // Function to fetch local user data from localStorage
        const fetchUserData = async () => {
          try {
            const localUserData = localStorage.getItem('userData'); // Fetch user data from localStorage
            if (localUserData) {
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'Nativelog', message: "Native Plan Details _____^^_____" }));
              
              // Parse the stored JSON data
              const parsedData = JSON.parse(localUserData);

              // Dispatch parsed data to update state
              dispatch(ChangeUserData(parsedData));
              
              // Send log message to React Native app
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'parsedNativelog', message: parsedData }));
            } else {
              console.warn('No user data found in localStorage.');
            }
          } catch (error) {
            console.error('Error fetching user data from localStorage:', error);
          }
        };
    
        fetchUserData(); // Fetch data on component mount
    
        // Event handler function to receive messages from React Native app
        const handleMessage = (event) => {
          try {
            const { data } = event; // In the web context, it's just event.data
            const parsedMessage = JSON.parse(data);
            
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'console-log', message: "+++++++++++++" }));
            // Filter messages based on their type
            if (parsedMessage.type === 'user-data') {
              const parsedData = parsedMessage.data; // Assuming user data is inside `data`
              dispatch(ChangeUserData(parsedData));
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'parsed-(user-data)-Data', message: parsedData }));
            } else if (parsedMessage.type === 'console-log') {
              console.log('Console log from WebView:', parsedMessage.message);
            } else if (parsedMessage.type === 'error') {
              console.log('Error from WebView:', parsedMessage.message);
            } else {
              console.log('Ignoring message from WebView:', parsedMessage);
            }
          } catch (error) {
            console.error('Error handling message from React Native app:', error);
          }
        };
    
        // Add event listener to receive messages from the parent React Native app
        window.addEventListener('message', handleMessage);
    
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      }, [dispatch]); // Only re-run the effect if dispatch changes (unlikely)
    
    
      useEffect(() => {
        // Set a timeout to update the state after 3 seconds
        const timer = setTimeout(() => {
          setIsTimerFinished(true);
        }, 2000);
    
        // Clear the timeout if the component is unmounted before the timer finishes
        return () => clearTimeout(timer);
      }, []);

    return (
        <div>
            {!isTimerFinished ? 
                    <p>Loading...</p>
                    // <Loader noPush={true}/>
                :
                    <div className='signIn__container'>
                            <p>{JSON.stringify(userData?.email)}</p>
                        <div className='signIn__container-inner signin-inner'>
                            <Typography
                                variant='h5'
                            >
                                {"Native Plan Details"}
                            </Typography>
                            <div className='text__feilds'>
                                <div className='row'>
                                    <Box
                                        component="form"
                                        sx={{
                                            m: 2,
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                border: '0px solid red',
                                                height: 125,
                                                justifyContent: 'space-between',
                                                padding: '16px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                                component={"span"}
                                            >
                                                <Typography
                                                    component={"span"}
                                                    sx={{
                                                        color: 'yellow'
                                                    }}
                                                >
                                                    {"> "}
                                                </Typography>
                                                {"  Watch Shows"}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                                component={"span"}
                                            >
                                                <Typography
                                                    component={"span"}
                                                    sx={{
                                                        color: 'yellow'
                                                    }}
                                                >
                                                    {"> "}
                                                </Typography>
                                                {"  Create your own"}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                                component={"span"}
                                            >
                                                <Typography
                                                    component={"span"}
                                                    sx={{
                                                        color: 'yellow'
                                                    }}
                                                >
                                                    {"> "}
                                                </Typography>
                                                {"  No Ads"}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                                component={"span"}
                                            >
                                                <Typography
                                                    component={"span"}
                                                    sx={{
                                                        color: 'yellow'
                                                    }}
                                                >
                                                    {"> "}
                                                </Typography>
                                                {"  Cancel Anytime"}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Box
                                            sx={{
                                                border: '0px solid red',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    border: '0px solid red',
                                                    width: matches ? '40ch' : 'unset',
                                                    // width: 100,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        border: '0px solid red',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        p: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: 'white'
                                                        }}
                                                    >
                                                        Monthly Price
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: 'white'
                                                        }}
                                                    >
                                                         $9.99
                                                    </Typography>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt: 3,
                                                    mb: 3,
                                                }}
                                            >
                                                <Elements
                                                    stripe={stripePromise}
                                                >
                                                    <ContinueToPaymentLink />
                                                </Elements>
                                            </Box>
                                            {/* <ButtonWrapper type="subscription" /> */}
                                        </Box>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div >
            }
        </div>
    )
}

export default NativePlanDetails
