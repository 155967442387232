import { useSelector } from 'react-redux';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import LandingPage from '../views/backend/pages/auth/Landing/LandingPage';
import SignIn from '../views/backend/pages/auth/SignIn';
import SignUp from '../views/backend/pages/auth/SignUp';
import PrivacyPolicy from '../views/backend/pages/extrapages/privacyPolicy';
import TermsOfUse from '../views/backend/pages/extrapages/termsOfUse';
import Payment from '../views/backend/pages/Payment/Payment';
import PlanDetails from '../views/backend/pages/Payment/PlanDetails';
import FAQ from './../views/backend/pages/auth/Landing/FAQs';
import HomeRedirect from './HomeRedirect';
import MovieDetails from '../views/backend/movie/add-movie';
import Contact from '../views/backend/pages/contact';
import NativePlanDetails from '../views/backend/pages/Payment/NativePlanDetails';


const AuthPages = () => {

    let location = useLocation();
    let { path } = useRouteMatch();

    const { userData } = useSelector((state) => state.shared)

    return (
        <Switch location={location}>
            <Route path="/sign-up" component={SignUp} />
            <Route path="/sign-in" component={SignIn} />
            <Route exact path={path} component={LandingPage} />
            <Route path={`${path}details/:movieUrl`} component={MovieDetails} />
            {userData &&
                <>
                    <Route path="/plan-details" component={PlanDetails} />
                    <Route path="/payment" component={Payment} />
                    <Route path="/" component={HomeRedirect} />
                </>
            }
            <Route path="/plan-details" component={NativePlanDetails} />
            <Route path="/faq" component={FAQ} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-service" component={TermsOfUse} />
            <Route path="/contact" component={Contact} />
        </Switch>
    )
}

export default AuthPages;