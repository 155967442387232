import { useDispatch } from "react-redux";
import { Subscription } from "../../../../store/Actions/userActions";
// import { publicKey, testPricePlan } from "../../../../config/apiKeys";

const ContinueToPaymentLink = () => {
    // const stripe = require('stripe')(publicKey);
    // const price = pricePlan;
    // const price = testPricePlan;
    const dispatch = useDispatch()


    const handleSubmit = async (event) => {
        dispatch(Subscription())
    };
    return ( 
        <div
            className="btn btn-hover iq-button iq-button text-medium navbar--button signup-button"
            onClick={() =>
                handleSubmit()
            }
            style={{
                marginTop: 0,
            }}
        >
            <span
                className='button-txt'
                style={{
                    color: 'rgb(99,91,255)',
                    fontWeight: 'bold',
                }}
            >
                Stripe Checkout
            </span>
        </div>
     );
}
 
export default ContinueToPaymentLink;