export const getVideoDimensions = (file) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            resolve(video.videoWidth);
        };

        video.onerror = reject;

        video.src = URL.createObjectURL(file);
    });
};

export const getAudioDuration = (file) => {
    return new Promise((resolve, reject) => {
        const audio = new Audio();
        audio.onloadedmetadata = () => {
            window.URL.revokeObjectURL(audio.src);
            resolve(audio.duration);
        };
        audio.onerror = () => {
            // If there's an error, we'll resolve with null instead of rejecting
            resolve(null);
        };
        audio.src = URL.createObjectURL(file);
    });
};