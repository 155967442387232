import React, { useEffect, useState } from 'react'
import {  Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../../assets/custom-assets/logo.png'
// import { baseURL } from '../../../../config/baseURL'

const HeaderStyleLoggedOut = (props) => {
    
    let location = useLocation();

    const [isTouchedTop, ChangeIsTouchedTop] = useState(true)


    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            if (document.documentElement.scrollTop > 0)
                ChangeIsTouchedTop(false)
            else
                ChangeIsTouchedTop(true)
        })
    }, [])


    return (
        <>
            <header
                id="main-header"
                className={isTouchedTop ? 'touched-top-header my-header' : 'not-touched-top-header my-header'}
            >
                <div className="main-header">
                    <Container fluid>
                        <Row>
                            <Col sm="10">
                                <Navbar style={{ flexWrap: 'nowrap' }} className='custom-navbar p-0' expand="lg" >
                                    <Navbar.Toggle className="c-toggler">
                                        <div className="navbar-toggler-icon"  >
                                            <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                                        </div>
                                    </Navbar.Toggle>
                                
                                        <Navbar.Brand className={`${location.pathname === "/publisher" ? "invisible" : "navbar-brand"} `} href="/">
                                            <img className="img-fluid logo" src={logo} alt="multitude" />
                                        </Navbar.Brand>
                                    <Navbar.Collapse>
                                        <div className="menu-main-menu-container">
                                            <Nav as="ul" id="top-menu" className="ml-auto">
                                                <li className="menu-item">
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li className="menu-item">
                                                    <Link to="/new-episodes">New</Link>
                                                </li>
                                                <li className="menu-item">
                                                    <Link to="/trending">Trending</Link>
                                                </li>
                                            </Nav>
                                        </div>
                                    </Navbar.Collapse>
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header>
        </>
    )
}

export default HeaderStyleLoggedOut