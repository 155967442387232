import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  GetShowDetails,
  PlayNow,
} from "../../../../store/Actions/movieActions";


export default function RenderShowSection({show, popularMovies}) {
  const dispatch = useDispatch();
  const {
    addCast,
    category,
    description,
    id,
    landscape_image,
    maturity_level,
    name,
    release_date
  } = show;

  let stringCategories = "";
  category.map((item, key) => {
    const { category_name } = item;
    if (key === 0) stringCategories = category_name;
    else stringCategories = stringCategories + ", " + category_name;
    return null
  });

  let stringCasts = "";
  addCast.map((item, key) => {
    const { addCast_name } = item;
    if (key === 0) stringCasts = addCast_name;
    else stringCasts = stringCasts + ", " + addCast_name;
    return null
  });

  return (
    <section
      id="parallex"
      style={{
        backgroundImage: `url(${landscape_image})`,
        marginTop: popularMovies.length === 0 ? 0 : 40,
      }}
      className="parallax-window"
    >
      <Container
        fluid
        className="h-200 custom-container middle-poster-container"
      >
        <Row className="align-items-center justify-content-center h-100 parallaxt-details">
          <Col lg="6" className="r-mb-23">
            <div className="text-left">
              <div className="parallax-ratting d-flex align-items-center mt-3 mb-3">
                <h1
                  className="slider-text big-title title text-uppercase"
                  data-iq-gsap="onStart"
                  data-iq-position-x="-200"
                >
                  {name}
                </h1>
              </div>
              <div className="movie-time d-flex align-items-center mb-3 iq-ltr-direction">
                {maturity_level !== "" && (
                  <div className="badge badge-secondary mr-3 ">
                    {maturity_level}
                  </div>
                )}
                <h6 className="text-white font-xsmall">
                  {moment(release_date).format("MMM Do YY")}
                </h6>
              </div>
              <p>{description}</p>
              <div
                className="cat-cast-div trending-list"
                data-wp_object-in="fadeInUp"
                data-delay-in="1.2"
              >
                {stringCasts !== "" && (
                  <div className="text-primary title genres font-small">
                    Staring: <span className="text-body">{stringCasts}</span>
                  </div>
                )}
                <div className="text-primary title genres font-small">
                  Categories:{" "}
                  <span className="text-body">{stringCategories}</span>
                </div>
              </div>
              <div className="parallax-buttons">
                <div
                  className="btn btn-hover iq-button font-small iq-button"
                  onClick={() => dispatch(PlayNow(id))}
                  style={{ color: "black" }}
                >
                  <i className="fa fa-play mr-2" aria-hidden="true"></i>
                  Play Now
                </div>
                <span
                  onClick={() => dispatch(GetShowDetails(id))}
                  className="btn btn-link font-xsmall"
                >
                  More details
                </span>
              </div>
            </div>
          </Col>
          <Col lg="6"></Col>
        </Row>
      </Container>
    </section>
  );
}
