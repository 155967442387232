import { useDispatch } from "react-redux";
import { FollowShow } from "../../../../store/Actions/movieActions";


const FollowShowIcon = ({followedShowsIds, id}) => {
    const dispatch = useDispatch();

    return ( 
        <li
        onClick={() => {
            if (followedShowsIds.includes(id)) {
            dispatch(FollowShow(id, false));
            } else {
            dispatch(FollowShow(id, true));
            }
        }}
        >
            <span>
                {followedShowsIds.includes(id) ? (
                <i className="custom-icon ri-check-line" />
                ) : (
                <i className="custom-icon ri-add-line" />
                )}
            </span>
        </li>
     );
}
 
export default FollowShowIcon;