import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RenderSlides } from "../helperComponents/RenderSlides";
import { useEffect, useState } from "react";
import Loader from "../../../../router/Loader";

const UpcomingShows = ({ upComingShows, dislikedShowsIds, likedShowsIds, followedShowsIds, userData}) => {
    
  const [showDiv, setShowDiv] = useState()

  // Handle show state and cleanup
  useEffect(() => {
    const timer = setTimeout(() => setShowDiv(true), 7000);
    return () => clearTimeout(timer);
  }, []);
  return ( 
    <section id="iq-upcoming-movie" className="iq-rtl-direction">
      <Container fluid>
        <Row>
          <Col sm="12" className="overflow-hidden">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="main-title">
                <Link to="#">Upcoming Shows</Link>
              </h4>
            </div>
            <div id="upcoming-contens">
              {upComingShows?.length
                ? <RenderSlides dislikedShowsIds={dislikedShowsIds} likedShowsIds={likedShowsIds} followedShowsIds={followedShowsIds} showList={upComingShows} slideId={2} userData={userData}/>
                : showDiv ? "No Upcoming Shows To Load" : <Loader noPush={true}/>}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
 
export default UpcomingShows;