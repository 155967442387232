import React, { useState, useCallback, useRef } from 'react'
import { Col, Container, Row, Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ChangeProgressEvent, UploadCurrentEpisodeAxiosSequential, ChangeUploadFinal, pauseUpload, resumeUpload, cancelUpload } from '../../store/Actions/uploadActions';
import { useHistory } from 'react-router-dom';
import { multipartFileUpload } from '../../store/Actions/uploadActions';

const CHUNK_SIZE = 1024 * 1024 * 5; // 5MB chunks

const VideoUpload = props => {
    const { episodeTitle, episodeId } = (props.location && props.location.state) || {};
    const history = useHistory()
    const [fileName, setFileName] = useState('')
    const [episode, setEpisode] = useState(null)
    const [progress, setProgress] = useState({})
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState(false)
    const [chunkIndex, setChunkIndex] = useState(0);
    const [totalChunks, setTotalChunks] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [uploadedSize, setUploadedSize] = useState(0);
    const [chunkQueue, setChunkQueue] = useState([]);
    const [activeUploads, setActiveUploads] = useState(0);
    const cancelTokenSourceRef = useRef(null);

    const dispatch = useDispatch()
    const { progressEvent, uploadIsFinal, uploadProgress } = useSelector((state) => state.movie);

    const handleTrailerUpload = event => {
        const file = event.target.files[0]
        setEpisode(file)
        setFileName(file.name)
        setTotalSize(file.size)
    }

    const handleSubmit = event => {
        event.preventDefault()
        setUploadError(false)
        dispatch(ChangeUploadFinal(false))
        
        if (episode) {
            setUploading(true)
            setUploadedSize(0)
            dispatch(multipartFileUpload(episode, episodeId, history));
        }
    }

    const handlePauseResume = () => {
        if (isPaused) {
            dispatch(resumeUpload());
        } else {
            dispatch(pauseUpload());
        }
        setIsPaused(prev => !prev);
    }

    const handleCancel = () => {
        dispatch(cancelUpload());
    }

    useEffect(() => {
        if (uploadProgress) {
            console.log({uploadProgress})
            const totalChunksProgress = parseInt(uploadProgress?.data?.totalChunks) || 0
            const chunkIndexProgress = parseInt(uploadProgress?.data?.chunkIndex) + 1 || 0
            const chunkProgress = (chunkIndexProgress / totalChunksProgress) * 100

            console.log({totalChunksProgress, chunkIndexProgress, chunkProgress})

            const mbProgress = (CHUNK_SIZE * chunkIndexProgress)

            if (chunkProgress > 0 && chunkProgress < 100 && mbProgress > 0) {
                setProgress({
                    chunkProgress,
                    mbProgress,
                })
            }
        }
    }, [uploadProgress, progressEvent, uploadedSize, totalSize])

    console.log({progress})
    return (
        <Container>


            <Row>
                <Col sm={12}>
                    <div className='publisher-card'>
                        <div className='publisher-card-header justify-content-between'>
                            <h4>Add Video for Episode {episodeTitle}</h4>
                        </div>
                        <div className='publisher-card-body'>
                            <Row>
                                <Col lg={12}>
                                    <Form method='POST' encType='multipart/form-data' onSubmit={handleSubmit}>
                                        <Form.Group className='form_episode_upload' controlId='trailerFile'>
                                            <Row>
                                                <Form.Label className='btn btn-primary form_video-upload_button' style={{ marginLeft: 15 }}>
                                                    Choose File
                                                    <Form.Control type="file" name='trailer' onChange={handleTrailerUpload} accept='video/mp4,video/x-m4v,video/*' multiple hidden/>
                                                </Form.Label>
                                                <div style={{ marginLeft: 10 }}>
                                                    {fileName}
                                                </div>
                                            </Row>
                                        </Form.Group>
                                        <label htmlFor="file-upload">Uploading progress: {progress?.chunkProgress?.toFixed(0) || 0}%</label>
                                        <progress id='file-upload' value={progress?.chunkProgress || 0} max={100}> Complete</progress><br/>
                                        { uploading && !uploadIsFinal && (


                                        <div className='upload-not-final'>
                                            <img
                                                alt="loader"
                                                src={require('../../assets/custom-assets/loader.gif').default}
                                                style={{
                                                    height: 50,
                                                    width: 50,
                                                    borderRadius: 5,
                                                }}
                                            />
                                            <p>Uploading... Please wait.</p>
                                        </div>)
                                        }
                                        {uploadError && <p>Error Uploading Episode. Please Try again or Choose a different File</p>}
                                        <p>{`${(progress?.mbProgress / 1000000).toFixed(2) > 0 ? (progress?.mbProgress / 1000000).toFixed(2) : 0} MB / ${(totalSize / 1000000).toFixed(2) || 0} MB`}</p>
                                        <Form.Group className='col-12'>
                                            <button type='submit' className='btn btn-primary form_video-upload_button' disabled={uploading}>Submit</button>
                                            {uploading && (
                                                <>
                                                    <Button onClick={handlePauseResume} className='btn btn-secondary ml-2'>
                                                        {isPaused ? 'Resume' : 'Pause'}
                                                    </Button>
                                                    <Button onClick={handleCancel} className='btn btn-danger ml-2'>
                                                        Cancel
                                                    </Button>
                                                </>
                                            )}
                                        </Form.Group>

                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default VideoUpload
