import { useDispatch } from "react-redux";
import { DislikeShow, LikeShow } from "../../../../store/Actions/sharedActions";

const LikeShowIcon = ({likedShowsIds, dislikedShowsIds, id}) => {
    const dispatch = useDispatch();
    return ( 
        <li className="share">
                    <span className="">
                      <i className="custom-icon ri-heart-fill"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center">
                        {likedShowsIds.includes(id) ? (
                          <i
                            onClick={() => dispatch(LikeShow(id))}
                            className="hover-icon ri-thumb-up-fill"
                          />
                        ) : (
                          <i
                            onClick={() => dispatch(LikeShow(id))}
                            className="hover-icon ri-thumb-up-line"
                          />
                        )}
                        {dislikedShowsIds.includes(id) ? (
                          <i
                            onClick={() => dispatch(DislikeShow(id))}
                            className="hover-icon ri-thumb-down-fill"
                          />
                        ) : (
                          <i
                            onClick={() => dispatch(DislikeShow(id))}
                            className="hover-icon ri-thumb-down-line"
                          />
                        )}
                      </div>
                    </div>
                  </li>
     );
}
 
export default LikeShowIcon;