import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
    CardElement, useElements,
    useStripe, PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ChangeLoader, Subscription } from '../../../../../store/Actions/userActions';
import './CheckoutForm.css';
import {useEffect, useState} from 'react'
import badge from '../../../../../assets/custom-assets/stripe_badge.png'
import ButtonWrapper from './ButtonWrapper';
// import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";


const CheckoutForm = ({history}) => {
    const [couponCode, setCouponCode] = useState('')
    const [paymentRequest, setPaymentRequest] = useState(null);


    const options = {
        style: {
            base: {
                fontSize: 12,
                color: "white",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#9e2146"
            }
        }
    }
    const handleChange = event => {
        setCouponCode(event.target.value);
        console.log('value is:', event.target.value);
      };

    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch()

    const handleSubmit = async (event) => {
        event.preventDefault();

        dispatch(ChangeLoader(true))

        if (elements == null) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (error) {
            dispatch(ChangeLoader(false))
            toast(error?.message)
        }

        if (paymentMethod) {
            const {
                id,
            } = paymentMethod || {}
            dispatch(Subscription({paymentMethod: id, couponCode, history}))
        }

    };

    useEffect(() => {
        if (stripe) {
          const pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
              label: 'Demo total',
              amount: 1000,
            },
            requestPayerName: true,
            requestPayerEmail: true,
          });
    
          // Check the availability of the Payment Request API first.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
          pr.on('paymentmethod', async (ev) => {
            // replace this with client_secret you got when you created the PaymentIntent on the server
            const clientSecret = '{CLIENT_SECRET}'; 
            const {error, paymentIntent} = await stripe.confirmCardPayment(
              clientSecret,
              {
                payment_method: ev.paymentMethod.id,
              },
              {handleActions: false}
            );
          
            if (error) {
              // Report to the browser that the payment failed.
              ev.complete('fail');
              return;
            }
          
            // Report to the browser that the confirmation was successful.
            ev.complete('success');
          
            // Let Stripe.js handle the rest of the payment flow.
            const {error: confirmError} = await stripe.confirmCardPayment(paymentIntent.client_secret);
          
            if (confirmError) {
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment succeeded!
            }
          });
        }
      });
    }
  }, [stripe]);
    
      
    return (
        <div className='signIn__container'>
            <div
                className='signIn__container-inner signin-inner'
                style={{
                    minWidth: '370px'
                }}
            >
                <Typography
                    variant='h5'
                >
                    {"Payment"}
                </Typography>
                <div className='text__feilds'>
                    <div className='row'>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': {
                                    m: 2,
                                    width: '40ch',
                                    border: '0px solid red',
                                },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Typography>
                                Your Plan: $9.99/month
                            </Typography>
                            <CardElement
                                options={options}
                            />
                            <Typography style={{marginTop: "2rem", marginBottom: "0"}}>
                                Coupon Code:
                            </Typography>
                            <input
                                type="text"
                                id="coupon-code"
                                name="coupon-code"
                                onChange={handleChange}
                                value={couponCode}
                            />

                        </Box>
                    </div>
                </div>
                {paymentRequest ? (
    <PaymentRequestButtonElement options={{ paymentRequest }} />
) : null}
                
                <div
                    className="btn btn-hover iq-button iq-button text-medium navbar--button signup-button"
                    onClick={handleSubmit}
                    style={{
                        marginBottom: '20px',
                    }}
                >
                    <a
                        href='/new-episodes'
                        className='button-txt'
                        style={{
                            color: 'white',
                        }}
                    >
                        {"Subscribe"}
                    </a>
                </div>
                <ButtonWrapper type="subscription" />
                {/* <PayPalButtons 
                    style={{ 
                        shape: 'pill',
                        color: 'gold',
                        layout: 'horizontal',
                        label: 'subscribe' 
                    }} 
                    createSubscription={createSubscription}
                    onApprove={onApprove}
                    catchError={(err) => console.log(err)}
                /> */}
                <div>
                    <img style={{ width: '150px', height: '100px' }} src={badge} alt='Powered by Stripe' />
                </div>
            </div>
        </div>
    );
};

export default CheckoutForm