import React, {useEffect, useState} from 'react'
import { Table, Breadcrumb } from 'react-bootstrap'
import PublisherCard from '../PublisherCard'
import { useSelector, useDispatch } from 'react-redux';
import ShowDetailsIcon from './WatchShowIcon';
import EditContentIcon from './EditContentIcon';
import DeleteConfirmBox from './DeleteConfirmBox';
import { GetAllEpisodesOfSpecificSeason } from './../../../store/Actions/movieActions';

const EpisodesList = ({setCompSwitch, seasonId, tvShowId, currentComponent, setSidebarComponent, seasonTitle, showName}) => {
    const { currentSeasonEpisodes, refreshSidebar } = useSelector((state) => state.movie);
    const [show, setShow] = useState()
    // const [showsInCorrectOrder, setShowsInCorrectOrder] = useState()

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    const handleChangePage = (page) => {
        setCurrentPage(page);
    }

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(0); // Reset to first page when items per page changes
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(0); // Reset to first page when search term changes
    }

    // Filter shows based on search term
    // Slice shows based on current page and items per page
    // Calculate total pages
    const filteredShows = currentSeasonEpisodes?.filter(episode => episode.title.toLowerCase().includes(searchTerm.toLowerCase()));
    console.log("filteredShows: ", filteredShows)
    const totalPages = Math.ceil(filteredShows?.length / itemsPerPage);
    const currentItems = filteredShows?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    console.log("currentItems: ", currentItems)


    const dispatch = useDispatch()
    console.log("currentSeasonEpisodes: ", currentSeasonEpisodes);
    
    useEffect(() => {
        console.log("ran GET EPISODES");
        dispatch(GetAllEpisodesOfSpecificSeason(seasonId, true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshSidebar]);

    // useEffect(() => {
    //     setShowsInCorrectOrder(currentSeasonEpisodes.sort((a, b) => (a.id > b.id) ? 1 : -1))
    // }, [currentSeasonEpisodes])

    useEffect(() => {
            let timer1 = setTimeout(() => setShow(true), 6000);
            return () => {
              clearTimeout(timer1);
            };
      }, []);
    return (
        <PublisherCard 
            title={ `Episodes List` }
            totalEntries={filteredShows?.length}
            currentPage={currentPage}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            itemsPerPage={itemsPerPage}
            handleChangeItemsPerPage={handleChangeItemsPerPage}
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
        >
            <Breadcrumb className="main-bg">
                  <Breadcrumb.Item 
                    onClick={() => setCompSwitch({string: "Show", showId: ''})}  
                  >
                    Show List
                  </Breadcrumb.Item>
                  <Breadcrumb.Item 
                    onClick={() => setCompSwitch({string: "Season", seasonId: seasonId})}  
                  >
                    Season List
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Episodes List</Breadcrumb.Item>
            </Breadcrumb>
            <Table className='dataTable'>
                <thead>
                    <tr>
                        <th tabIndex={0} aria-label='Episode' style={{ width: '59px', paddingRight: '30px' }}>Episode</th>
                        <th tabIndex={0} aria-label='Show Title' style={{ width: '196px' }}>Show Title</th>
                        <th tabIndex={0} aria-label='Seasons No' style={{ width: '206px' }}>Season</th>
                        <th tabIndex={0} aria-label='Episode No' style={{ width: '194px' }}>Episode Title</th>
                        <th tabIndex={0} aria-label='Views' style={{ width: '123px' }}>Views</th>
                        <th tabIndex={0} aria-label='Release Date' style={{ width: '177px' }}>Release Date</th>
                        <th tabIndex={0} aria-label='Release Date' style={{ width: '177px' }}>Status</th>
                        <th tabIndex={0} aria-label='Action' style={{ width: '138px' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentSeasonEpisodes[0]?.season_id === seasonId ? currentItems.filter((episode => episode.tv_show_id === tvShowId)).map((episode) => {
                        const date = episode.release_date
                        return (
                            <tr key={episode.id}>
                                <td>{episode.episode_number}</td>
                                <td>{showName}</td>
                                <td>{seasonTitle}</td>
                                <td>{episode.title}</td>
                                <td>{episode.views_count || 0}</td>
                                <td>{date}</td>
                                <td>Proccessed: {episode?.process_status}%</td>
                                <td>
                                    <ShowDetailsIcon showId={tvShowId} isEpisode={episode.id}/>
                                    {' '}
                                    <EditContentIcon setSidebarComponent={setSidebarComponent} contentId={episode.id} switchComponent="EditEpisode" />
                                    {' '}
                                    <DeleteConfirmBox currentComponent={currentComponent} currentId={episode.id}/>
                                </td>
                            </tr>
                        )
                    })
                    :
                     <tr >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                        {!show ?
                            <img
                                alt="loader"
                                src={require('../../../assets/custom-assets/loader.gif').default}
                                style={{
                                    height: 50,
                                    width: 50,
                                    borderRadius: 5,
                                    marginLeft: '-20%'
                                    
                                }}
                            />
                            :
                            "No Episodes Found"
                        
                        }
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                }
                </tbody>
            </Table>
        </PublisherCard>
    )
}

export default EpisodesList
