
import { useEffect } from 'react';
import { VerifyActiveSubscription } from '../store/Actions/userActions';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const HomeRedirect = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(VerifyActiveSubscription(history))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ( 
        <div 
        // style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}
        >
            {/* <img
                alt="loader"
                src={require('../assets/custom-assets/loader.gif').default}
                style={{
                    height: 150,
                    width: 150,
                    borderRadius: 5,
                }}
            /> */}
        </div>
     );
}
 
export default HomeRedirect;