import React from 'react';
import { AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getOS } from '../../../../../config/helperFunctions';
import { ChangeGetStartedEmail } from '../../../../../store/Actions/sharedActions';

function GetStarted() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { getStartedEmail } = useSelector(state => state.shared);

    return (
        <>
            {(getOS() !== "iOS" && getOS() !== "Android" && getOS() !== "Windows Phone") &&
            <>
                <p className='hero__section--text-tertiary'>
                    Enter your email to start your membership.
                </p>
                <div className='hero__section__search '>
                    <input
                        onChange={(e) => {
                            dispatch(ChangeGetStartedEmail(e.target.value));
                        }}
                        value={getStartedEmail}
                        className='hero__section-input'
                        placeholder='Email Address'
                    />
                    <button
                        onClick={() => {
                            if (getOS() !== "iOS" && getOS() !== "Android" && getOS() !== "Windows Phone") {
                                history.push('/sign-up');
                            }
                        }}
                        className='hero__section-search-button'
                    >
                        <span className='hero__section-search-button-text font-weight-bold'>
                            JOIN
                        </span>
                        <AiOutlineArrowRight className='hero__section-search-button-icon os-icon' />
                    </button>
                </div>
                <div style={{ height: 50 }} />
            </>
            }
        </>
    )
}

export default GetStarted