import { toast } from 'react-toastify'
import store from '../'
import { baseURL } from '../../config/baseURL'
import axios from 'axios';
import {
    SET_RESUME_DATA,
    CHANGE_PROGRESS_EVENT,
    CHANGE_UPLOAD_FINAL,
    CHUNK_UPLOAD_COMPLETE,
    CHANGE_UPLOAD_PROGRESS,
    PAUSE_UPLOAD,
    RESUME_UPLOAD,
    CANCEL_UPLOAD,
    
} from './types'
import { throttle } from '../../config/helperFunctions'
import { getVideoDimensions, getAudioDuration } from '../../utils/mediaUtils'




//STATE MANAGMENT
export const pauseUpload = (val) => {
    return async (dispatch) => {
        dispatch({ type: PAUSE_UPLOAD})
    }
}

export const resumeUpload = (val) => {
    return async (dispatch) => {
        dispatch({ type: RESUME_UPLOAD})
    }
}
export const cancelUpload = (val) => {
    return async (dispatch) => {
        dispatch({ type: CANCEL_UPLOAD})
    }
}

export const ChangeUploadFinal = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true})
    }
}

export const ChangeProgressEvent = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_PROGRESS_EVENT, payload: val})
    }
}

export const UploadCurrentEpisodeAxiosParallel = (file, episodeId, history, setIsPaused) => {
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunks
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 3000; // 3 seconds
    const MAX_PARALLEL_UPLOADS = 3; // Number of parallel uploads
    
    return async (dispatch, getState) => {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let startChunkIndex = 0;
        let abortController = new AbortController();

        // Check for resume data
        const resumeData = getState().movie.resumeData;
        if (resumeData && resumeData.fileId === file.name + file.size) {
            startChunkIndex = resumeData.lastChunkIndex + 1;
            toast.info(`Resuming upload from chunk ${startChunkIndex}`);
        }

        const uploadChunk = async (chunkIndex) => {
            if (getState().movie.isCancelled) {
                abortController.abort();
                dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
                toast.info('Upload cancelled');
                return;
            }

            const chunk = file.slice(chunkIndex * CHUNK_SIZE, (chunkIndex + 1) * CHUNK_SIZE);
            const formData = new FormData();
            formData.append('chunk', chunk, file.name);
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunks', totalChunks);

            let retries = 0;
            while (retries < MAX_RETRIES) {
                try {
                    const response = await axios.post(
                        `${baseURL}/api/publisher/episode/video/upload/${episodeId}`,
                        formData,
                        {
                            headers: {
                                'Authorization': `Bearer ${store.getState().shared.userData.token}`,
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                dispatch({ 
                                    type: CHANGE_PROGRESS_EVENT, 
                                    payload: { 
                                        percent: percentCompleted, 
                                        chunkIndex, 
                                        totalChunks 
                                    } 
                                });
                            },
                            signal: abortController.signal,
                        }
                    );

                    dispatch({ type: CHUNK_UPLOAD_COMPLETE });
                    dispatch({ type: CHANGE_UPLOAD_PROGRESS, payload: response.data });

                    // Save resume data
                    dispatch({
                        type: SET_RESUME_DATA,
                        payload: {
                            fileId: file.name + file.size,
                            lastChunkIndex: chunkIndex
                        }
                    });

                    return true;
                } catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message);
                        return false;
                    }
                    console.error(`Upload error for chunk ${chunkIndex}:`, error);
                    retries++;
                    if (retries < MAX_RETRIES) {
                        toast.warning(`Retrying chunk ${chunkIndex} (Attempt ${retries + 1})`);
                        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                    } else {
                        toast.error(`Failed to upload chunk ${chunkIndex} after ${MAX_RETRIES} attempts`);
                        return false;
                    }
                }
            }
            return false;
        };

        const uploadChunksInParallel = async () => {
            for (let i = startChunkIndex; i < totalChunks; i += MAX_PARALLEL_UPLOADS) {
                if (getState().movie.isPaused) {
                    await new Promise(resolve => {
                        const checkPaused = () => {
                            if (!getState().movie.isPaused) {
                                resolve();
                            } else {
                                setTimeout(checkPaused, 1000);
                            }
                        };
                        checkPaused();
                    });
                }

                const chunkPromises = [];
                for (let j = 0; j < MAX_PARALLEL_UPLOADS && i + j < totalChunks; j++) {
                    chunkPromises.push(uploadChunk(i + j));
                }

                const results = await Promise.all(chunkPromises);
                if (results.includes(false)) {
                    dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
                    return false;
                }
            }
            return true;
        };

        const uploadSuccess = await uploadChunksInParallel();

        if (uploadSuccess) {
            setTimeout(() => {
                console.log("Upload complete");
                toast.success('File Uploaded Successfully');
                dispatch({ type: CHANGE_UPLOAD_FINAL, payload: false });
                // Clear resume data
                dispatch({ type: SET_RESUME_DATA, payload: null });
                history && history.push('/publisher');
            }, 60000); // 60 seconds delay as in your original code
        }
    };
};

export const UploadCurrentEpisodeAxiosSequential = (file, episodeId, history, setIsPaused) => {
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunks
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 3000; // 3 seconds
    
    return async (dispatch, getState) => {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let startChunkIndex = 0;
        let abortController = new AbortController();
        let uploadId = null;
        console.log({uploadId});

        // Check for resume data
        const resumeData = getState().movie.resumeData;
        if (resumeData && resumeData.fileId === file.name + file.size) {
            startChunkIndex = resumeData.lastChunkIndex + 1;
            uploadId = resumeData.uploadId;
            toast.info(`Resuming upload from chunk ${startChunkIndex}`);
        }

        const uploadChunk = async (chunkIndex) => {
            console.log({chunkIndex});
            if (getState().movie.isCancelled) {
                abortController.abort();
                dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
                toast.info('Upload cancelled');
                return false;
            }

            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min((chunkIndex + 1) * CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);
            const formData = new FormData();
            formData.append('chunk', chunk, file.name);
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunks', totalChunks);
            formData.append('fileSize', file.size);
            
            if (uploadId) formData.append('uploadId', uploadId);

            let retries = 0;
            while (retries < MAX_RETRIES) {
                try {
                    const response = await axios.post(
                        `${baseURL}/api/publisher/episode/video/upload/${episodeId}`,
                        formData,
                        {
                            headers: {
                                'Authorization': `Bearer ${store.getState().shared.userData.token}`,
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                dispatch({ 
                                    type: CHANGE_PROGRESS_EVENT, 
                                    payload: { 
                                        percent: percentCompleted, 
                                        chunkIndex, 
                                        totalChunks,
                                        uploadedSize: start + progressEvent.loaded,
                                        totalSize: file.size
                                    } 
                                });
                            },
                            signal: abortController.signal,
                        }
                    );

                    if (chunkIndex === 0 && response.data.uploadId) {
                        uploadId = response.data.uploadId;
                    }

                    dispatch({ type: CHUNK_UPLOAD_COMPLETE });
                    dispatch({ type: CHANGE_UPLOAD_PROGRESS, payload: response.data });

                    // Save resume data
                    dispatch({
                        type: SET_RESUME_DATA,
                        payload: {
                            fileId: file.name + file.size,
                            lastChunkIndex: chunkIndex,
                            uploadId: uploadId
                        }
                    });

                    return true;
                } catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message);
                        return false;
                    }
                    console.error(`Upload error for chunk ${chunkIndex}:`, error);
                    retries++;
                    if (retries < MAX_RETRIES) {
                        toast.warning(`Retrying chunk ${chunkIndex} (Attempt ${retries + 1})`);
                        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                    } else {
                        toast.error(`Failed to upload chunk ${chunkIndex} after ${MAX_RETRIES} attempts`);
                        return false;
                    }
                }
            }
            return false;
        };

        const uploadChunksSequentially = async () => {
            for (let i = startChunkIndex; i < totalChunks; i++) {
                if (getState().movie.isPaused) {
                    await new Promise(resolve => {
                        const checkPaused = () => {
                            if (!getState().movie.isPaused) {
                                resolve();
                            } else {
                                setTimeout(checkPaused, 1000);
                            }
                        };
                        checkPaused();
                    });
                }

                const success = await uploadChunk(i);
                if (!success) {
                    dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
                    return false;
                }
            }
            return true;
        };

        const uploadSuccess = await uploadChunksSequentially();

        if (uploadSuccess) {
            try {
                const verifyResponse = await axios.post(
                    `${baseURL}/api/publisher/episode/verify-upload/${episodeId}`,
                    { fileSize: file.size, uploadId: uploadId },
                    {
                        headers: {
                            'Authorization': `Bearer ${store.getState().shared.userData.token}`,
                        },
                    }
                );
                
                if (verifyResponse.data.success) {
                    console.log("Upload complete and verified");
                    toast.success('File Uploaded Successfully');
                    dispatch({ type: CHANGE_UPLOAD_FINAL, payload: false });
                    dispatch({ type: SET_RESUME_DATA, payload: null });
                    history && history.push('/publisher');
                } else {
                    throw new Error('Upload verification failed');
                }
            } catch (error) {
                console.error("Upload verification failed:", error);
                toast.error('Upload verification failed. Please try again.');
                dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
            }
        }
    };
};

export const UploadCurrentEpisode = (formData, episodeId, history, isLastChunk) => {
    return async (dispatch) => {
        const req = new XMLHttpRequest();

        req.open('POST', baseURL + `/api/publisher/episode/video/upload/${episodeId}`, true);
        req.setRequestHeader('Authorization', 'Bearer ' + store.getState().shared.userData.token);

        const throttledDispatch = throttle((event) => {
            dispatch({ type: CHANGE_PROGRESS_EVENT, payload: event });
        }, 500);

        req.upload.addEventListener('progress', throttledDispatch);

        req.addEventListener('load', () => {
            if (req.status >= 200 && req.status < 300) {
                if (isLastChunk) {
                    //wait 60 seconds
                    setTimeout(() => {
                        // dispatch({ type: CHANGE_UPLOAD_FINAL, payload: false });
                        console.log("Uploading complete");
                        console.log({req});
                        toast.success('File Uploaded Successfully');
                        // history && history.push('/publisher');
                    }, 60000);
                } else {
                    dispatch({ type: CHUNK_UPLOAD_COMPLETE });

                    console.log("Chunk uploaded successfully");
                    dispatch({ type: CHANGE_UPLOAD_PROGRESS, payload: JSON.parse(req.response) });
                    // console.log(req);
                    // console.log(req.responseText);

                }
            } else {

                dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
                toast.error('Error Uploading File');
                console.log('Failure: ' + req.status);
                console.log("Response: " + req.response);
            }
        });

        req.addEventListener('error', () => {
            dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
            toast.error('Network Error Uploading File');
            console.error('Network Error');
        });

        req.addEventListener('abort', () => {
            dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
            toast.info('Upload Aborted');
            console.info('Upload Aborted');
        });

        req.send(formData);
    }
}


export const multipartFileUpload = (file, episodeId, history) => {
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB

    return async (dispatch) => {
        const fileName = file.name;
        const fileType = file.type;
        let uploadId = "";
        let parts = [];
        let videoWidth = 0;
        let videoDuration = null;

        try {
            // Get video dimensions and duration
            try {
                videoWidth = await getVideoDimensions(file);
                videoDuration = await getAudioDuration(file);
            } catch (error) {
                console.error("Error getting video metadata:", error);
                // If there's an error, we'll continue with the upload process
                // but with default or null values for width and duration
            }

            console.log(`Video width: ${videoWidth}px, Duration: ${videoDuration !== null ? videoDuration + ' seconds' : 'Unknown'}`);

            // Start the multipart upload
            const startUploadResponse = await axios.post(
                `${baseURL}/api/publisher/episode/start-upload/${episodeId}`,
                {
                    fileName,
                    fileType,
                    width: videoWidth || null,
                    duration: videoDuration,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${store.getState().shared.userData.token}`,
                    },
                }
            );

            uploadId = startUploadResponse.data.uploadId;
            const key = startUploadResponse.data.key;

            // Split the file into chunks and upload each part
            const totalParts = Math.ceil(file.size / CHUNK_SIZE);

            for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
                const start = (partNumber - 1) * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, file.size);
                const fileChunk = file.slice(start, end);

                const reader = new FileReader();
                reader.readAsArrayBuffer(fileChunk);

                await new Promise((resolve, reject) => {
                    reader.onload = async () => {
                        const fileChunkBase64 = btoa(
                            new Uint8Array(reader.result).reduce(
                                (data, byte) => data + String.fromCharCode(byte),
                                ""
                            )
                        );

                        try {
                            const uploadPartResponse = await axios.post(
                                `${baseURL}/api/publisher/episode/upload-part/${episodeId}`,
                                {
                                    fileName,
                                    partNumber,
                                    uploadId,
                                    fileChunk: fileChunkBase64,
                                    key,
                                },
                                {
                                    headers: {
                                        'Authorization': `Bearer ${store.getState().shared.userData.token}`,
                                    },
                                }
                            );

                            parts.push({
                                ETag: uploadPartResponse.data.ETag,
                                PartNumber: partNumber,
                            });

                            // Dispatch progress update
                            dispatch({
                                type: CHANGE_PROGRESS_EVENT,
                                payload: {
                                    percent: (partNumber / totalParts) * 100,
                                    chunkIndex: partNumber - 1,
                                    totalChunks: totalParts,
                                    uploadedSize: end,
                                    totalSize: file.size
                                }
                            });
                            dispatch({ type: CHANGE_UPLOAD_PROGRESS, payload: uploadPartResponse.data });
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    };
                    reader.onerror = reject;
                });
            }
            console.log({
                fileName,
                uploadId,
                parts,
                key,
                width: videoWidth, // Include video width in the completion request as well
            })
            // All parts have been uploaded, now complete the multipart upload
            const completeUploadResponse = await axios.post(
                `${baseURL}/api/publisher/episode/complete-upload/${episodeId}`,
                {
                    fileName,
                    uploadId,
                    parts,
                    key,
                    width: videoWidth || null,
                    duration: videoDuration,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${store.getState().shared.userData.token}`,
                    },
                }
            );
            
            dispatch({ type: CHANGE_UPLOAD_FINAL, payload: false });
            // dispatch({ type: SET_VIDEO_WIDTH, payload: videoWidth }); // Dispatch video width to store
            toast.success('File uploaded successfully');
            console.log({completeUploadResponse});
            history && history.push('/publisher');
        } catch (error) {
            console.error("Error uploading file:", error);
            dispatch({ type: CHANGE_UPLOAD_FINAL, payload: true });
            toast.error('Error uploading file. Please try again.');
        }
    };
};

// ... rest of the file ...