import React from 'react'
import AuthPages from '../router/auth-pages-route'
import HeaderStyleLoggedOut from '../components/partials/backend/headerstyle/headerstyleLoggedOut';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Footerstyle from '../components/partials/backend/footerstyle/footerstyle';

const BlankLayout = () => {

    const location = useLocation();

    return (
        <>
        {location.pathname !== "/" ? <HeaderStyleLoggedOut /> : null}
            <div className={location.pathname !== "/" ? "content-page" : ""}>
                <AuthPages />
            </div>
        {location.pathname !== "/" ? <Footerstyle /> : null}
        </>
    )
}

export default BlankLayout;