import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from "react-transition-group"
import Profile from '../views/backend/app/usermanagement/profile'
import UserProfile from '../views/backend/app/usermanagement/userprofile'
import ShowsByCategory from '../views/backend/categories/ShowsByCategory'
import Following from '../views/backend/following/Following'
import Homepage from '../views/backend/home/home'
import MovieDetails from '../views/backend/movie/add-movie'
import NewEpisodes from '../views/backend/movie/NewEpisodes'
import AboutUs from '../views/backend/pages/about-us'
import Contact from '../views/backend/pages/contact'
import PrivacyPolicy from '../views/backend/pages/extrapages/privacyPolicy'
import TermsOfUse from '../views/backend/pages/extrapages/termsOfUse'
import FAQ from './../views/backend/pages/auth/Landing/FAQs';
import ShowsBySearchQuery from '../views/backend/ShowsBySearchQuery/ShowsBySearchQuery'
import ShowList from "../components/publisher/ShowList"
import Trending from '../views/backend/trending/Trending'
import PublisherPage from '../views/backend/publisher/publisher'
import ShowAnalytics from './../components/publisher/ShowAnalytics';
import VideoUpload from './../components/publisher/VideoUpload';
import Loader from './Loader'
import PaymentSuccessful from './../views/backend/pages/Payment/PaymentSuccessful';

const MainLayoutRoute = () => {
    let location = useLocation()
    let { path } = useRouteMatch();

    return (
        <TransitionGroup>
            <CSSTransition
                classNames="fade"
                timeout={300}
            >
                <Switch location={location}>
                    <Route path="/" exact component={Homepage} />
                    <Route path="/new-episodes" component={NewEpisodes} />
                    <Route path="/trending" component={Trending}/>
                    <Route path="/following" component={Following}/>
                    <Route path="/shows-by-category" component={ShowsByCategory} />
                    <Route path="/publisher" component={PublisherPage} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/manage-profile" component={UserProfile} />
                    <Route path="/faq" component={FAQ} />
                    <Route path="/terms-of-service" component={TermsOfUse} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/about-us" component={AboutUs} />
                    <Route path="/contact" component={Contact} />
                    <Route path={`${path}details/:movieUrl`} component={MovieDetails} />
                    <Route path="/show-list" component={ShowList} />
                    <Route path="/shows-by-search-query" component={ShowsBySearchQuery} />
                    <Route path="/show" component={ShowAnalytics} />
                    <Route path="/upload" component={VideoUpload} />
                    <Route path="/plan-details" component={Loader} />
                    <Route path="/payment-successful" component={PaymentSuccessful} />                  
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default MainLayoutRoute