import {baseSharingURL} from '../../../../config/baseURL';

export const CopyLink = (name, id) => {
    let dummy = document.createElement('input')
    let text;
    if (id) {
        text = baseSharingURL + `${name.split(" ").join("-").toLowerCase()}-${id}`;
    } else {
        text = window.location.href;
    }
    
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
}