import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import 'swiper/swiper-bundle.css'
import { FollowShow, GetShowDetails, PlayNow } from '../../../store/Actions/movieActions'
import {
    DislikeShow, LikeShow
} from '../../../store/Actions/sharedActions'

function RenderLikedShows({likedList}) {
    const dispatch = useDispatch();
    const {
        dislikedShowsIds,
        likedShowsIds,
        followedShowsIds,
    } = useSelector((state) => state.shared);

    return likedList.map((item, key) => {
        
        const { id, name, maturity_level, release_date, portrait_image } = item
        return (
            <li key={id} className="red-border col-md-2 col-lg-2 mb-4 pl-0 pr-0 slide-item">
                <div className="block-images position-relative card-container">
                    <div className="img-box">
                        <img
                            src={portrait_image}
                            className="img-fluid vertical-card-img"
                            alt=""
                        />
                    </div>
                    <div className="block-description">
                        <div className="iq-title">
                            <h6 style={{ cursor: 'pointer' }} onClick={() => dispatch(PlayNow(id))} className="iq-title text-large">{name}</h6>
                        </div>
                        <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                            {maturity_level !== "" &&
                                <div className="badge badge-secondary p-1 mr-2 text-medium">{maturity_level}</div>
                            }
                            <span className="text-white text-medium">{moment(release_date).format("MMM Do YY")}</span>
                        </div>
                        <div
                            className="btn btn-hover iq-button font-small"
                            onClick={() => dispatch(PlayNow(id))}
                            style={{ color: 'black' }}
                        >
                            <i className="fa fa-play mr-2"
                                aria-hidden="true"></i>
                            Play Now
                        </div>
                    </div>

                    <div className="block-social-info">
                        <ul className="list-inline p-0 m-0 music-play-lists">
                            <li>
                                <span onClick={() => dispatch(GetShowDetails(id))} className=''><i className="custom-icon ri-information-fill"></i></span>
                            </li>
                            <li className="share">
                                <span className=''>
                                    <i className="custom-icon ri-heart-fill"></i>
                                </span>
                                <div className="share-box">
                                    <div className="d-flex align-items-center">
                                        {likedShowsIds.includes(id) ?
                                            <i
                                                onClick={() => dispatch(LikeShow(id))}
                                                className="hover-icon ri-thumb-up-fill"
                                            />
                                            :
                                            <i
                                                onClick={() => dispatch(LikeShow(id))}
                                                className="hover-icon ri-thumb-up-line"
                                            />
                                        }
                                        {dislikedShowsIds.includes(id) ?
                                            <i
                                                onClick={() => dispatch(DislikeShow(id))}
                                                className="hover-icon ri-thumb-down-fill"
                                            />
                                            :
                                            <i
                                                onClick={() => dispatch(DislikeShow(id))}
                                                className="hover-icon ri-thumb-down-line"
                                            />
                                        }
                                    </div>
                                </div>
                            </li>
                            <li
                                onClick={() => {

                                    if (followedShowsIds.includes(id)) {
                                        dispatch(FollowShow(id, false))
                                    } else {
                                        dispatch(FollowShow(id, true))
                                    }

                                }}
                            >
                                <span className=''>
                                    {followedShowsIds.includes(id) ?
                                        <i className="custom-icon ri-check-line" />
                                        :
                                        <i className="custom-icon ri-add-line" />
                                    }
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        )
    })
}

export default RenderLikedShows;