import {
    ADD_DOWNLOADIND_EPISODE_IDS,
    CHANGE_CONNECTION_TYPE,
    CHANGE_DISLIKED_SHOWS_IDS,
    CHANGE_DOWNLOADING_SHOW,
    CHANGE_DOWNLOADING_SHOW_PROGRESS,
    CHANGE_FOLLOWED_SHOWS_IDS,
    CHANGE_GET_STARTED_EMAIL,
    CHANGE_IS_BANNER_PLAY_NOW_BTN_ACTIVE,
    CHANGE_IS_CONNECTED,
    CHANGE_IS_PANNEL_PLAY_NOW_BTN_ACTIVE,
    CHANGE_LIKED_SHOWS_IDS,
    CHANGE_LOADER,
    CHANGE_SHOW_CHANGE_QUALITY,
    CHANGE_SHOW_DETAIL_EPISODES_LOADER,
    CHANGE_SHOW_EPISODES_SIDEBAR,
    CHANGE_SHOW_FULL_SCREEN_PLAYER,
    CHANGE_SHOW_ID_IN_EPISODES_PER_SEASON,
    CHANGE_STATUS,
    CHANGE_USER_DATA, CHANGE_USER_PROFILE,
    CHANGE_YAXIS,
    DISLIKE_SHOW,
    FOLLOW_SHOW,
    LIKE_SHOW,
    REMOVE_DOWNLOADIND_EPISODE_IDS,
    REMOVE_LOADER_AND_RESET_STATUS,
    RTLMODE,
    UNFOLLOW_SHOW,
    SUBSCRIBE_USER,
    UNSUBSCRIBE_USER,
} from '../Actions/types'

const initialState = {
    status: '',
    loader: false,
    getStartedEmail: '',

    yAxis: 0,

    downloadingEpisodeIds: [],

    userData: null,

    showFullScreenPlayer: false,
    showEpisodesSidebar: false,
    showChangeQuality: false,

    showDetailEpisodesLoader: false,

    connectionType: null,
    isConnected: null,

    isPannelPlayNowBtnActive: true,
    isBannerPlayNowBtnActive: true,

    showIdInEpisodesPerSeason: 0,

    likedShowsIds: [],
    dislikedShowsIds: [],

    followedShowsIds: [],

    rtlMode: 'ltr'
}

const sharedReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UNSUBSCRIBE_USER:
            let unsubscribedUserData = {
                ...state.userData,
                is_subscribed: false,
            }
            localStorage.setItem('userData', JSON.stringify(unsubscribedUserData))
            return {
                ...state,
                userData: unsubscribedUserData,
            }
        case SUBSCRIBE_USER:
            let subscribedUserData = {
                ...state.userData,
                is_subscribed: true,
            }
            localStorage.setItem('userData', JSON.stringify(subscribedUserData))
            return {
                ...state,
                userData: subscribedUserData,
            }
        case CHANGE_USER_PROFILE:
            let userData = {
                ...state.userData,
                profile_photo_path: payload?.profile_image,
            }
            localStorage.setItem('userData', JSON.stringify(userData))
            return {
                ...state,
                userData,
            }
        case CHANGE_GET_STARTED_EMAIL:
            return {
                ...state,
                getStartedEmail: payload
            }
        case CHANGE_SHOW_CHANGE_QUALITY:
            return {
                ...state,
                showChangeQuality: payload,
            }
        case CHANGE_SHOW_EPISODES_SIDEBAR:
            return {
                ...state,
                showEpisodesSidebar: payload,
            }
        case CHANGE_YAXIS:
            return {
                ...state,
                yAxis: payload,
            }
        case RTLMODE:
            return {
                ...state,
                rtlMode: state.followedShowsIds.concat([payload])
            }
        case CHANGE_FOLLOWED_SHOWS_IDS:
            var followedShowsIdsTemp = []
            payload.map(item => followedShowsIdsTemp.push(item.tv_show_id))
            return {
                ...state,
                followedShowsIds: followedShowsIdsTemp
            }
        case FOLLOW_SHOW:
            return {
                ...state,
                followedShowsIds: state.followedShowsIds.concat([payload])
            }
        case UNFOLLOW_SHOW:
            return {
                ...state,
                followedShowsIds: state.followedShowsIds.filter(id => id !== payload)
            }
        case DISLIKE_SHOW:
            let dislikedShowsIdsTemp
            dislikedShowsIdsTemp = state.dislikedShowsIds.includes(payload) ? state.dislikedShowsIds.filter(id => id !== payload) : [payload, ...state.dislikedShowsIds]
            let likedShowsIdsTemp
            likedShowsIdsTemp = state.dislikedShowsIds.includes(payload) ? state.likedShowsIds : state.likedShowsIds.filter(id => id !== payload)
            return {
                ...state,
                likedShowsIds: likedShowsIdsTemp,
                dislikedShowsIds: dislikedShowsIdsTemp
            }
        case LIKE_SHOW:
            let likedShowsIdsTemp2
            likedShowsIdsTemp2 = state.likedShowsIds.includes(payload) ? state.likedShowsIds.filter(id => id !== payload) : [payload, ...state.likedShowsIds]
            let dislikedShowsIdsTemp2
            dislikedShowsIdsTemp2 = state.likedShowsIds.includes(payload) ? state.dislikedShowsIds : state.dislikedShowsIds.filter(id => id !== payload)
            return {
                ...state,
                dislikedShowsIds: dislikedShowsIdsTemp2,
                likedShowsIds: likedShowsIdsTemp2
            }
        case CHANGE_LIKED_SHOWS_IDS:
            let likedShowsIdsTemp3 = []
            payload.map(item => likedShowsIdsTemp3.push(item.tv_show_id))
            return {
                ...state,
                likedShowsIds: likedShowsIdsTemp3
            }
        case CHANGE_DISLIKED_SHOWS_IDS:
            var dislikedShowsIdsTemp4 = []
            payload.map(item => dislikedShowsIdsTemp4.push(item.tv_show_id))
            return {
                ...state,
                dislikedShowsIds: dislikedShowsIdsTemp4
            }
        case CHANGE_SHOW_ID_IN_EPISODES_PER_SEASON:
            return {
                ...state,
                showIdInEpisodesPerSeason: payload
            }
        case CHANGE_DOWNLOADING_SHOW_PROGRESS:
            let downloadindEpigodeIdsTemp = state.downloadingEpisodeIds
            let index = downloadindEpigodeIdsTemp.findIndex(obj => obj.episodeId === payload.episodeId)
            if (index !== -1)
                downloadindEpigodeIdsTemp[index].progress = payload.progress
            return {
                ...state,
                downloadingEpisodeIds: downloadindEpigodeIdsTemp
            }
        case REMOVE_DOWNLOADIND_EPISODE_IDS:
            return {
                ...state,
                downloadingEpisodeIds: state.downloadingEpisodeIds.filter(obj => obj.episodeId !== payload)
            }
        case ADD_DOWNLOADIND_EPISODE_IDS:
            let downloadindEpigodeIdsTemp2 = state.downloadingEpisodeIds
            downloadindEpigodeIdsTemp2.push({ episodeId: payload, progress: 0 })
            return {
                ...state,
                downloadingEpisodeIds: downloadindEpigodeIdsTemp2,
            }
        case CHANGE_IS_BANNER_PLAY_NOW_BTN_ACTIVE:
            return {
                ...state,
                isBannerPlayNowBtnActive: payload,
            }
        case CHANGE_IS_PANNEL_PLAY_NOW_BTN_ACTIVE:
            return {
                ...state,
                isPannelPlayNowBtnActive: payload,
            }
        case CHANGE_IS_CONNECTED:
            return {
                ...state,
                isConnected: payload,
            }
        case CHANGE_CONNECTION_TYPE:
            return {
                ...state,
                connectionType: payload,
            }
        case CHANGE_DOWNLOADING_SHOW:
            return {
                ...state,
                downloadingShow: payload,
            }
        case CHANGE_SHOW_DETAIL_EPISODES_LOADER:
            return {
                ...state,
                showDetailEpisodesLoader: payload,
            }
        case CHANGE_SHOW_FULL_SCREEN_PLAYER:
            return {
                ...state,
                showFullScreenPlayer: payload,
            }
        case CHANGE_USER_DATA:
            localStorage.setItem('userData', JSON.stringify(payload))
            return {
                ...state,
                userData: payload,
            }
        case CHANGE_STATUS:
            return {
                ...state,
                status: payload,
            }
        case REMOVE_LOADER_AND_RESET_STATUS:
            return {
                ...state,
                loader: false,
                status: '',
            }
        case CHANGE_LOADER:
            return {
                ...state,
                loader: payload
            }
        default:
            return state;
    }
}

export default sharedReducer;