import Hls from 'hls.js';
import moment from 'moment';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch, useSelector } from 'react-redux';
import { ControlBar, Player } from 'video-react';
import '../assets/css/custom/custom.css';
import '../assets/css/custom/full-screen-video-player.css';
import EpisodesSidebar from '../components/EpisodesSidebar';
import { GetEpisodeVideos, PauseEpisode } from '../store/Actions/movieActions';
import {
    ChangeShowChangeQuality,
    ChangeShowEpisodesSidebar, ChangeShowFullScreenPlayer
} from '../store/Actions/sharedActions';
import ChangeQuality from './ChangeQuality';
import { AddViewToEpisode } from './../store/Actions/movieActions';
// import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenPlayer(props) {
    let timeOuts = 0;

    const hlsRef = useRef(null);

    // Initialize hls instance once
    if (!hlsRef.current) {
        const hlsConfig = {
            "debug": true,
            "enableWorker": true,
            "lowLatencyMode": true,
            "backBufferLength": 90,
        };
        hlsRef.current = new Hls(hlsConfig);
    }

    const hls = hlsRef.current;

    const handle = useFullScreenHandle();
    const dispatch = useDispatch();
    // const mounted = useRef(false);

    const { showFullScreenPlayer } = useSelector(state => state.shared);

    // fullScreenEpisode contains the Wasabi buckets/videos
    // selectedEpisodeCurrentTimeForFullScreen is just current Paused Time
    const { fullScreenEpisode, selectedEpisodeCurrentTimeForFullScreen, seasonDetail: { episode } } = useSelector(state => state.movie);

    // const [controllsShownOn, setControllsShownOn] = useStateWithCallbackLazy(0);
    const [currentTime, setCurrentTime] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [previousTime, setPreviousTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playable, setPlayable] = useState(0);
    const [seekValue, setSeekValue] = useState(0);
    const [watchTime, setWatchTime] = useState(0);
    const [playerRef, setPlayerRef] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [waiting, setWaiting] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [justSeeked, setJustSeeked] = useState(false);
    const [showControlls, setShowControlls] = useState(true);
    const [isInFullScreen, setIsInFullScreen] = useState(false);
    const [currentQualityIndex, setCurrentQualityIndex] = useState(0);
    const [skipNextDisabled, setSkipNextDisabled] = useState(false);
    const [skipPreviousDisabled, setSkipPreviousDisabled] = useState(false);
    const [isCountedAsView, setIsCountedAsView] = useState(false);

    let currentTimeRef = createRef();
    currentTimeRef.current = currentTime;

    const handlePauseEpisode = () => {
        if (currentTime > 5 && fullScreenEpisode?.id !== undefined && fullScreenEpisode?.id !== 0) {
            dispatch(PauseEpisode(fullScreenEpisode?.id, currentTime, watchTime));
        }
        setWatchTime(0);
    };

    const handleClose = () => {
        handlePauseEpisode();
        setIsPlaying(false);
        dispatch(ChangeShowFullScreenPlayer(false));
        setDuration(0);
    };

    const handlePlayPause = () => {
        // setControllsShownOn(currentTime);
        setShowControlls(true);
        if (!isPlaying) {
            setTimeout(() => {
                setShowControlls(false);
            }, 3000);
        }
        playerRef.actions.togglePlay();
        setIsPlaying(!isPlaying);
    };

    const handleJustSeeked = () => {
        setJustSeeked(true);
        clearTimeout(timeOuts);
        timeOuts = setTimeout(() => {
            setJustSeeked(false);
        }, 5000);
    };

    const handleFullScreenToggle = () => {
        if (isInFullScreen) {
            setIsInFullScreen(false);
            handle.exit();
        } else {
            setIsInFullScreen(true);
            handle.enter();
        }
    };

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        handlePauseEpisode();
    };

    const handleStateChange = (state, prevState) => {
        setDuration(state.duration);

        if (state.buffered.length) {
            let playableTemp = state.buffered.end(state.buffered.length - 1);
            setPlayable(((playableTemp / state.duration) * 100).toFixed(0) + "%");
        }
        if (state.currentTime >= state.duration) {
            setIsPlaying(false);
        }
        setWaiting(state.waiting);
        setCurrentTime(state.currentTime);
        setPreviousTime(prevState.currentTime);
        let difference = state.currentTime - prevState.currentTime;
        if (difference > 0 && difference < 1) {
            setWatchTime(prevState => prevState + difference);
        }
        setSeekValue(((state.currentTime / state.duration) * 100).toFixed(2));
    };

    const handleSkipEpisode = (isNext) => {
        const currentEpisodeId = fullScreenEpisode?.id;

        let currentEpisodeIndex = episode?.findIndex(item => item.episode_id === currentEpisodeId);

        if (currentEpisodeIndex || currentEpisodeIndex === 0) {
            let episodeId;
            if (isNext)
                episodeId = episode[currentEpisodeIndex + 1]?.episode_id;
            else
                episodeId = episode[currentEpisodeIndex - 1]?.episode_id;

            if (episodeId) {
                dispatch(GetEpisodeVideos(episodeId, false, true));
                handlePauseEpisode();
            } else
                console.error("Can't find episode Id");
        }
    };

    useEffect(() => {
        if (currentTime >= duration) {
            setIsPlaying(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (watchTime > 30 && !isCountedAsView) {
            setIsCountedAsView(true);
        }
        // window.addEventListener("beforeunload", handleBeforeUnload);
        // return () => {
        //     window.removeEventListener("beforeunload", handleBeforeUnload);
        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTime]);

    useEffect(() => {
        if (isCountedAsView) {
            dispatch(AddViewToEpisode(fullScreenEpisode.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCountedAsView]);

    useEffect(() => {
        let unsubscribe;

        if (playerRef) {
            playerRef.actions.seek(selectedEpisodeCurrentTimeForFullScreen);

            //* loading HLS video
            if (Hls.isSupported() && fullScreenEpisode?.videos.length !== 0) {
                let videoElement = document.querySelector(".full-screen-video-player video");
                hls.attachMedia(videoElement);

                let qualityIndexToUse = null;
                if (!fullScreenEpisode?.videos?.[currentQualityIndex]?.url) {
                    qualityIndexToUse = fullScreenEpisode?.videos.length - 1;
                    setCurrentQualityIndex(fullScreenEpisode?.videos.length - 1);
                } else
                    qualityIndexToUse = currentQualityIndex;

                hls.loadSource(fullScreenEpisode?.videos?.[qualityIndexToUse]?.url);
                setIsPlaying(false);
            }

            unsubscribe = playerRef.subscribeToStateChange(handleStateChange);
        }
        return () => {
            if (unsubscribe)
                unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerRef, fullScreenEpisode]);

    useEffect(() => {
        if (playerRef) {
            //* changing video quality
            if (Hls.isSupported() && fullScreenEpisode?.videos.length !== 0 && fullScreenEpisode?.videos.length > currentQualityIndex) {
                let videoElement = document.querySelector(".full-screen-video-player video");
                hls.attachMedia(videoElement);

                let qualityIndexToUse = null;
                if (!fullScreenEpisode?.videos?.[currentQualityIndex]?.url) {
                    qualityIndexToUse = fullScreenEpisode?.videos.length - 1;
                    setCurrentQualityIndex(fullScreenEpisode?.videos.length - 1);
                } else
                    qualityIndexToUse = currentQualityIndex;

                hls.loadSource(fullScreenEpisode?.videos?.[qualityIndexToUse]?.url);
                playerRef.actions.seek(currentTime);
                playerRef.actions.play();
                setIsPlaying(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQualityIndex]);

    useEffect(() => {
        const currentEpisodeId = fullScreenEpisode?.id;

        let currentEpisodeIndex = episode?.findIndex(item => item.episode_id === currentEpisodeId);

        if (currentEpisodeIndex || currentEpisodeIndex === 0) {
            let episodeIdNext = episode[currentEpisodeIndex + 1]?.episode_id;
            let episodeIdPrevious = episode[currentEpisodeIndex - 1]?.episode_id;

            if (!episodeIdNext)
                setSkipNextDisabled(true);
            else
                setSkipNextDisabled(false);

            if (!episodeIdPrevious)
                setSkipPreviousDisabled(true);
            else
                setSkipPreviousDisabled(false);
        }
    }, [fullScreenEpisode, episode]);

    return (
        <>
            <Dialog
                className='red-border'
                fullScreen
                open={showFullScreenPlayer}
                onClose={handleClose}
                TransitionComponent={Transition}
            >

                <FullScreen
                    className='red-border'
                    handle={handle}
                >
                    <Player
                        ref={(ref) => {
                            setPlayerRef(ref);
                        }}
                        className='full-screen-video-player'
                        fluid={false}
                        autoPlay={false}
                        loop={false}
                        muted={false}
                        poster={fullScreenEpisode?.landscape_image}
                    >
                        <ControlBar disableCompletely >
                        </ControlBar>
                    </Player>
                    <div
                        className='red-border'
                        onClick={() => {
                            // setControllsShownOn(currentTime);
                            setShowControlls(true);
                        }}
                    >
                        <div className='shown-controlls-container'>
                            {showControlls &&
                                <>
                                    <div className='all-controlls-container red-border'>
                                        <div className='left-controlls red-border'>
                                            <IconButton
                                                style={{ marginRight: 5 }}
                                                onClick={handleClose}
                                            >
                                                <ArrowBackIcon
                                                    className='icon'
                                                    sx={{ fontSize: 40 }}
                                                />
                                            </IconButton>
                                            <Typography style={{ marginLeft: 20, marginTop: 10 }} variant="h6" gutterBottom component="div">
                                                {fullScreenEpisode?.title}
                                            </Typography>
                                        </div>
                                        <div className='right-controlls red-border'>
                                            <IconButton
                                                style={{ marginRight: 5 }}
                                                onClick={() => {
                                                    console.log("CLICKED");
                                                    setTimeout(() => {
                                                        setShowControlls(false);
                                                    }, 200);
                                                }}
                                            >
                                                <ToggleOnRoundedIcon
                                                    className='icon'
                                                    sx={{ fontSize: 80 }}
                                                />
                                                Controls
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className='play-pause-btn-container red-border'>
                                        {waiting ?
                                            <CircularProgress size={70} color='primary' />
                                            :
                                            <IconButton
                                                onClick={handlePlayPause}
                                            >
                                                {isPlaying ?
                                                    <PauseCircleOutlineIcon
                                                        color='primary'
                                                        sx={{ fontSize: 80 }}
                                                    />
                                                    :
                                                    <PlayCircleOutlineIcon
                                                        color='primary'
                                                        sx={{ fontSize: 80 }}
                                                    />
                                                }
                                            </IconButton>
                                        }
                                    </div>
                                    <div className='slider-container red-border'>
                                        <div className='current-time-div red-border'>
                                            <span className='current-time'>
                                                {currentTime > 3599
                                                    ? moment.utc(currentTime * 1000).format('hh:mm:ss')
                                                    : moment.utc(currentTime * 1000).format('mm:ss')}
                                            </span>
                                        </div>
                                        <div className='slider red-border'>
                                            <div className='slider-container red-border'>
                                                <div className='payable-seek-container'>
                                                    <div
                                                        className='payable-seek'
                                                        style={{
                                                            height: '100%',
                                                            backgroundColor: '#FFCA00',
                                                            width: playable,
                                                            opacity: 0.6,
                                                        }}
                                                    />
                                                </div>
                                                <Slider
                                                    size="medium"
                                                    defaultValue={0}
                                                    value={seekValue}
                                                    aria-label="Small"
                                                    onChange={(event, newValue) => {
                                                        let seekTo = (newValue / 100) * duration;
                                                        playerRef.actions.seek(seekTo);
                                                        // setControllsShownOn(seekTo, () => {
                                                        // });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='duration-div red-border'>
                                            <span className='duration'>
                                                {currentTime > 3599
                                                    ? moment.utc(currentTime * 1000).format('hh:mm:ss')
                                                    : moment.utc(currentTime * 1000).format('mm:ss')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='all-controlls-container red-border'>
                                        <div className='left-controlls red-border'>
                                            <IconButton
                                                style={{ marginRight: 5 }}
                                                onClick={handlePlayPause}
                                            >
                                                {isPlaying ?
                                                    <PauseIcon
                                                        className='icon'
                                                        sx={{ fontSize: 55 }}
                                                    />
                                                    :
                                                    <PlayArrowIcon
                                                        className='icon'
                                                        sx={{ fontSize: 55 }}
                                                    />
                                                }
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    handleJustSeeked();
                                                    let seekTo = currentTime - 10;
                                                    playerRef.actions.seek(seekTo < 0 ? 0 : seekTo);
                                                }}
                                                style={{ marginRight: 5 }}
                                            >
                                                <Replay10Icon
                                                    className='icon'
                                                    sx={{ fontSize: 55 }}
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    handleJustSeeked();
                                                    let seekTo = currentTime + 10;
                                                    playerRef.actions.seek(seekTo > duration ? duration : seekTo);
                                                }}
                                                style={{ marginRight: 5 }}
                                            >
                                                <Forward10Icon
                                                    className='icon'
                                                    sx={{ fontSize: 55 }}
                                                />
                                            </IconButton>
                                            <IconButton
                                                disabled={skipPreviousDisabled}
                                                onClick={() => handleSkipEpisode(false)}
                                                style={{ marginRight: 5 }}
                                            >
                                                <SkipPreviousIcon
                                                    className='icon'
                                                    sx={{ fontSize: 55 }}
                                                />
                                            </IconButton>
                                            <IconButton
                                                disabled={skipNextDisabled}
                                                onClick={() => handleSkipEpisode(true)}
                                                style={{ marginRight: 5 }}
                                            >
                                                <SkipNextIcon
                                                    className='icon'
                                                    sx={{ fontSize: 55 }}
                                                />
                                            </IconButton>
                                        </div>
                                        <div className='right-controlls right-controlls-bottom red-border'>
                                            <IconButton
                                                onClick={() => dispatch(ChangeShowEpisodesSidebar(true))}
                                                style={{ marginLeft: 10 }}
                                            >
                                                <ViewSidebarIcon
                                                    className='icon'
                                                    sx={{ fontSize: 50 }}
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={handleFullScreenToggle}
                                                style={{ marginLeft: 10 }}
                                            >
                                                {isInFullScreen ?
                                                    <FullscreenExitIcon
                                                        className='icon'
                                                        sx={{ fontSize: 55 }}
                                                    />
                                                    :
                                                    <FullscreenIcon
                                                        className='icon'
                                                        sx={{ fontSize: 55 }}
                                                    />
                                                }
                                            </IconButton>
                                            <IconButton
                                                onClick={() => dispatch(ChangeShowChangeQuality(true))}
                                                style={{ marginLeft: 10 }}
                                            >
                                                <SettingsIcon
                                                    className='icon'
                                                    sx={{ fontSize: 40 }}
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </FullScreen>
            </Dialog>
            <EpisodesSidebar
                handlePauseEpisode={handlePauseEpisode}
            />
            <ChangeQuality
                setCurrentQualityIndex={setCurrentQualityIndex}
            />
        </>
    );
}

export default FullScreenPlayer;
