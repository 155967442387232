import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import { PayPalSubscription } from "../../../../../store/Actions/userActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";


const ButtonWrapper = ({ type }) => {
    const history = useHistory()
    const [{ options }, dispatch] = usePayPalScriptReducer();
    // const planId = "P-3CR49078L2003014YMRQYHCY" //sandbox acc test
    const planId = "P-43Y496846C388771WMROUNEQ" //live
    // const planId = "P-0K384026NB209813KMRQT7KQ" //test
    const mainDispatch = useDispatch()

    const subscribeUser = (params) => {
        mainDispatch(PayPalSubscription(params))
    }
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, dispatch]);

    return (<PayPalButtons
        createSubscription={(data, actions) => {
            return actions.subscription
                .create({
                    plan_id: planId, 
                })
                .then((orderId) => {
                    // Your code here after create the order
                    console.log({orderId})
                    console.log("Success")
                    return orderId;
                });
            }}
            onApprove={async (data, actions) => {
            const formData = {}
            formData.orderID = data.orderID
            formData.subscriptionID = data.subscriptionID
            formData.planId = planId
            formData.history = history
            console.log("approved transaction")
            return subscribeUser(formData)
        }}
        fundingSource="paypal"
        style={{
            label: "subscribe",
        }}
    />);
}

export default ButtonWrapper