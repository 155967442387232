import { useSelector } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import MainLayout from '../layouts/backend/main-layout'
import BlankLayout from '../layouts/blanklayout'

const LayoutsRoute = () => {
    const {userData} = useSelector((state) => state.shared);

    return (
        <BrowserRouter basename="#">
            <Switch>
               <Route path={userData?.is_subscribed === true ? "/" : ""} component={userData?.is_subscribed === true ? MainLayout : BlankLayout} />
            </Switch>
        </BrowserRouter>
    );
}

export default LayoutsRoute