import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const Loader = ({noPush}) => {
    const history = useHistory()

    useEffect(() => {
        !noPush && history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return ( 
        <div className='text-center'>
            <img
                alt="loader"
                src={require('../assets/custom-assets/loader.gif').default}
                style={{
                    height: 150,
                    width: 150,
                    borderRadius: 5,
                }}
            />
        </div>
     );
}
 
export default Loader;