import moment from 'moment'
import { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Col, Container, Row, Form, FormGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import '../../../../assets/css/custom/profile.css'
import { ChangeEditProfileModal, ChangeUpdatePasswordModal, EnableMonetization, GetUserProfile } from '../../../../store/Actions/userActions'
import EditProfileModal from './editProfileModal'
import UpdatePasswordModal from './UpdatePasswordModal'
import CancelMembershipModal from "./CancelMembershipModal"
import '../../../../assets/css/edit-profile-modal.css';
import '../../../../assets/css/signUp.css';
import UpdatePaymentModal from './UpdatePaymentModal'
import Toggle from '../../../../components/Toggle'


const Profile = () => {
   const dispatch = useDispatch()
   const { userProfile } = useSelector((state) => state.user)
   const {
      profile_image,
      profile_name,
      user_description,
      profile_email,
      profile_address,
      maturity_level,
      created_at,
   } = userProfile || {}
   const [monetizeEnable, setMonetizeEnable] = useState(false)
   const [formData, setFormData] = useState({
      name: '',
      ssn: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      w9: '',
  })

   useEffect(() => {
      dispatch(GetUserProfile())
   }, [dispatch])

   const onClick = e => {
      const { checked } = e.target;

      setMonetizeEnable(checked)
   }

   const handleInputChange = event => {
      const { name, value } = event.target

      setFormData(prevState => ({
         ...prevState,
         [name]: value
      }))
   }

   //! This is the function that will be called when the form is submitted

   const onSubmit = (event) => {
      event.preventDefault();
      dispatch(EnableMonetization(formData))
  };
   
   return (
      <>
         <section className="m-profile setting-wrapper">
            <Container>
               <h4 className="main-title mb-4">Account Setting</h4>
               <Row>
                  <Col lg="4" className="mb-3">
                     <div className="sign-user_card text-center">
                        <Avatar
                           className='profile-avatar'
                           src={profile_image}
                           name={profile_name?.[0]}
                           round
                           size="200px"
                        />
                        <h4 className="mb-3">{profile_name}</h4>
                        <p>
                           {user_description}
                        </p>
                        {/* <Link to="#" className="edit-icon text-primary">Edit</Link> */}
                     </div>
                  </Col>
                  <Col lg="8">
                     <div className="sign-user_card">
                        <Row className="row align-items-center justify-content-between mb-3">
                           <Col md="8">
                              <h5 className="mb-3 pb-3 a-border">Personal Details</h5>
                           </Col>
                           <Col md="4" className="text-md-right text-left mb-4">
                              <i
                                 onClick={() => dispatch(ChangeEditProfileModal(true))}
                                 className="ri-edit-2-fill text-primary font-size-20 clickable-icon"
                              />
                           </Col>
                        </Row>
                        <Row className="row align-items-center justify-content-between mb-3">
                           <Col md="8">
                              <span className="text-light font-size-13">Email</span>
                              <p className="mb-0">{profile_email}</p>
                           </Col>
                        </Row>
                        {profile_address &&
                           <Row className="row align-items-center justify-content-between mb-3">
                              <Col md="8">
                                 <span className="text-light font-size-13">Address</span>
                                 <p className="mb-0">{profile_address}</p>
                              </Col>
                           </Row>
                        }
                        {maturity_level &&
                           <Row className="row align-items-center justify-content-between mb-3">
                              <Col md="8">
                                 <span className="text-light font-size-13">Maturity Level</span>
                                 {maturity_level ?
                                    <p className="mb-0">{maturity_level}</p>
                                    :
                                    <p className="mb-0">{"N/A"}</p>
                                 }
                              </Col>
                           </Row>
                        }
                        <Row className="align-items-center justify-content-between mb-3">
                           <Col md="8">
                              <span className="text-light font-size-13">Member Since</span>
                              <p className="mb-0">{moment(created_at).format("MMM Do YY")}</p>
                           </Col>
                        </Row>
                        <Row className="align-items-center justify-content-between mb-3">
                           <Col md="8">
                              <span className="text-light font-size-13">Password</span>
                              <p className="mb-0">**********</p>
                           </Col>
                           <Col md="4" className="text-md-right text-left">
                              <Link
                                 onClick={() => dispatch(ChangeUpdatePasswordModal(true))}
                                 to="#"
                                 className="text-primary"
                              >
                                 {"Change"}
                              </Link>
                           </Col>
                        </Row>
                        <h5 className="mb-3 mt-4 pb-3 a-border">Billing Details</h5>
                        <Row className="justify-content-between mb-3">
                           <Col md="8" className="r-mb-15">
                              {/* <p>Your next billing date is 19 September 2020.</p> */}
                              <CancelMembershipModal/>    
                           </Col>
                           <div className="col-md-4 text-md-right text-left">
                              <UpdatePaymentModal/>
                           </div>
                        </Row>
                        <h5 className="mb-3 mt-4 pb-3 a-border">Plan Details</h5>
                        <Row className="justify-content-between mb-3">
                           <Col md="8">
                              <p>Premium</p>
                           </Col>
                           {/* <Col md="4" className="text-md-right text-left">
                              <Link to="#" className="text-primary">Change Plan</Link>
                           </Col> */}
                        </Row>
                        <h5 className="mb-3 mt-4 pb-3 a-border">Enable Monetization</h5>
                        <Row className="justify-content-between mb-3">
                           <Col md="8">
                              <Toggle label='Monetization Enabled' handleClick={onClick}></Toggle>
                           </Col>
                        </Row>
                        {monetizeEnable &&
                           <Row className="justify-content-between mb-3">
                              <Col md="8">
                                 <Form onSubmit={onSubmit}>
                                    <FormGroup className='col-md-8'>
                                          <Form.Label htmlFor='name'>Full Name</Form.Label>
                                          <Form.Control type='text' name='name' onChange={handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup className='col-md-8'>
                                          <Form.Label htmlFor='ssn'>SSN</Form.Label>
                                          <Form.Control type='text' name='ssn' onChange={handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup className='col-md-8'>
                                          <Form.Label htmlFor='address1'>Address 1</Form.Label>
                                          <Form.Control type='text' name='address1' onChange={handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup className='col-md-8'>
                                          <Form.Label htmlFor='address2'>Address2</Form.Label>
                                          <Form.Control type='text' name='address2' onChange={handleInputChange} />
                                    </FormGroup>
                                    <Row>
                                       <FormGroup className='col-md-4'>
                                             <Form.Label htmlFor='city'>City</Form.Label>
                                             <Form.Control type='text' name='city' onChange={handleInputChange} required />
                                       </FormGroup>
                                       <FormGroup className='col-md-4'>
                                             <Form.Label htmlFor='state'>State</Form.Label>
                                             <Form.Control type='text' name='state' onChange={handleInputChange} required />
                                       </FormGroup>
                                       <FormGroup className='col-md-4'>
                                             <Form.Label htmlFor='zipCode'>Zip Code</Form.Label>
                                             <Form.Control type='text' name='zipCode' onChange={handleInputChange} required />
                                       </FormGroup>
                                    </Row>
                                    <Row className="justify-content-between mb-3">
                                       <Col md="8" className="r-mb-15">
                                          <Form.Group controlId='w9File' style={{ margin: 0 }}>
                                             <Form.Label className="btn btn-hover" style={{ margin: 0 }}>
                                                Upload W9
                                                <Form.Control type="file" name='w9' accept='' onChange={handleInputChange} hidden/>
                                             </Form.Label>
                                          </Form.Group>
                                       </Col>
                                    </Row>
                                    <Row className="justify-content-between mb-3">
                                       <Col md="8" className="r-mb-15">
                                          <button type='submit' className="btn btn-hover">Submit</button>
                                       </Col>
                                    </Row>
                                 </Form>
                              </Col>
                           </Row>
                        }
                        {/* <h5 className="mb-3 pb-3 mt-4 a-border">Activity</h5> */}
                        {/* <Row>
                           <div className="col-12 setting"> */}
                              {/* <Link to="#" className="text-body d-block mb-1">Recent device streaming activity</Link> */}
                              {/* <Link to="#" className="text-body d-block mb-1">Sign out of all devices </Link> */}
                              {/* <Link to="#" className="text-body d-block">Download your person information</Link> */}
                           {/* </div>
                        </Row> */}
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
         <EditProfileModal />
         <UpdatePasswordModal />
      </>
   )
}

export default Profile